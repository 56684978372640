import React from 'react'
import PropTypes from 'prop-types'
import ErrorPage from 'pages/Error'
import { UserContext } from 'UserContext'

/**
 * Error boundary.
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state.
        return { hasError: true, err: error }
    }

    componentDidCatch(error, info) {
        console.error(error, info)
    }

    render() {
        if (this.state.hasError) {
            if (this.props.hasUserContext) {
                return (
                    <UserContext.Consumer>
                        {({ user }) => (
                            <ErrorPage err={this.state.err} user={user} />
                        )}
                    </UserContext.Consumer>
                )
            }

            return <ErrorPage err={this.state.err} />
        }

        return this.props.children
    }
}

ErrorBoundary.propTypes = {
    hasUserContext: PropTypes.bool,
    children: PropTypes.node
}

export default ErrorBoundary
