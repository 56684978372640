import React from 'react'
import { Redirect } from 'react-router-dom'
import { useUser } from 'UserContext'
import { getPersistLogin } from 'services/storage'
import { errorKind } from 'services/api'
import useApi from 'components/UseApi'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import LabeledInput from 'components/LabeledInput'
import Form from 'components/Form'

/**
 * This page expects to find the original email address and an opaque confirmation token in the query string parameters "orig" and "ct".
 * The token is used to confirm an email address change with our API, and the UserContext is then updated with the new user.
 * If the user isn't already authenticated, they will be prompted to enter their password first.
 * After the change is confirmed, the user will need to reauthenticate with the new email address. We automate this by using the already entered password.
 */
const ConfirmEmailChangePage = () => {
    const { user, setUser } = useUser()

    // Extract confirmation token from URL query string.
    const parsedURL = new URL(window.location.href)
    const origEmail = user ? user.email : parsedURL.searchParams.get('orig')
    const token = parsedURL.searchParams.get('ct')

    const [password, setPassword] = React.useState('')
    const [redirect, setRedirect] = React.useState()

    const [emailChange, doEmailChange] = useApi()

    // Hook to handle success.
    React.useEffect(() => {
        if (!emailChange.data) {
            return
        }
        setUser(emailChange.data)
    }, [emailChange.data, setUser])

    if (redirect) {
        return <Redirect push to={redirect} />
    }

    const onFormSubmit = () => {
        doEmailChange({
            method: 'post',
            endpoint: '/authenticate/web',
            body: {
                email: origEmail,
                password: password,
                persist: user && getPersistLogin()
            },
            transformError: (error) => {
                if (error.kind === errorKind.REAUTHENTICATION_REQUIRED) {
                    error.message = 'Incorrect password.'
                }
                return error
            },
            chain: () => {
                // Checking password succeeded.
                // Return a request config to change the email then automatically reauthenticate.
                return {
                    method: 'put',
                    endpoint: '/users/confirm-email',
                    body: {
                        confirmation_token: token
                    },
                    transformError: (error) => {
                        if (
                            error.kind === errorKind.REAUTHENTICATION_REQUIRED
                        ) {
                            error.message =
                                'Confirmation link expired, change email again to get a new link.'
                        }
                        return error
                    },
                    chain: (data) => {
                        // Email changed. Reauthenticate using previously entered password and new email.
                        return {
                            method: 'post',
                            endpoint: '/authenticate/web',
                            body: {
                                email: data.email,
                                password: password,
                                persist: user && getPersistLogin()
                            }
                        }
                    }
                }
            }
        })
    }

    return (
        <PageWrapper>
            <PageTitle>Confirm Email Change</PageTitle>
            {emailChange.data ? (
                <p>Email successfully changed to "{emailChange.data.email}".</p>
            ) : (
                <>
                    <p>
                        Please enter your password to confirm your email change.
                    </p>
                    <Form
                        disabled={emailChange.loading}
                        onSubmit={onFormSubmit}
                        onCancel={() => setRedirect('/')}
                        error={emailChange.error && emailChange.error.message}
                    >
                        <LabeledInput
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                            disabled={emailChange.loading}
                        />
                    </Form>
                </>
            )}
        </PageWrapper>
    )
}

export default ConfirmEmailChangePage
