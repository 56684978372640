import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import styles from './Legal.module.css'
import Link from 'components/Link'

const LegalPage = () => (
    <PageWrapper>
        <PageTitle>Terms of Use</PageTitle>
        <section className={styles.terms}>
            <aside>
                <p>
                    These Terms contain important provisions about your legal
                    rights, including limitations of liability, disclaimers of
                    warranties, and dispute resolution provisions. Please read
                    them carefully.
                </p>
            </aside>
            <ol>
                <li>
                    <p>
                        <b>Acceptance.</b> These Terms of Use ("<b>Terms</b>")
                        cover your use of and access to any products and
                        services (collectively, the "<b>Services</b>") made
                        available by Frazer Computing, LLC (the "<b>Company</b>
                        ") through this website. If you are accessing the
                        Services on behalf of an organization, then your use of
                        the Services means you are agreeing to these Terms both
                        on behalf of that organization and on your own behalf,
                        individually. Using the Services means you accept these
                        Terms. If you do not accept these Terms, immediately
                        leave this site and do not use the Services. "You" and
                        "your" as used in these Terms will mean any end user of
                        the Services, either an individual or a company.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Scope of Services.</b> The Company offers the
                        Services to facilitate your making payments using
                        electronic payment credentials. The Company grants you a
                        limited license to submit payments using the Services.
                        You do not receive any other rights in the Services
                        whatsoever by accessing or using the Services, and the
                        Company reserves the right to revoke your ability to use
                        the Services at any time.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Your Responsibilities in Using the Services.</b> You
                        promise that you will not use the Services for any
                        unlawful purpose or in an unlawful manner. You promise
                        to abide by all applicable laws in using the Services.
                        Without limiting the generality of these promises, you
                        specifically promise that you will not submit any
                        stolen, false, unauthorized, or otherwise fraudulent
                        payment credentials. You also represent and warrant to
                        the Company that any payment credentials you submit
                        through the Services have been validly obtained by you,
                        and that you have the necessary rights and authority to
                        submit those payment credentials, including for the
                        specific amount of any given payment. You also promise
                        that, to the extent you submit to the Company any
                        personally identifiable information, or any sensitive or
                        confidential information about any organization, you
                        have the authority to do so. You promise to make
                        reasonable efforts to safeguard the security of your
                        username and password used to access the Services and to
                        prevent any unauthorized access to your account.
                    </p>
                    <p>
                        These Terms do not excuse your performance with any
                        other agreements you may have with parties besides the
                        Company. You will remain responsible for complying with
                        the terms of any cardholder agreement that may exist
                        between you and any financial institution regarding your
                        use of any payment card or credentials. You will also
                        remain subject to any credit agreements or payment terms
                        that may exist between you and the business which you
                        are paying.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Third Parties.</b> There may be link to third party
                        websites or content on pages or portals through which
                        you access the Services. The Company does not control or
                        endorse any third-party content and bears no
                        responsibility for the same. Similarly, please
                        understand that there will be other business partners
                        involved in processing and accepting your payment,
                        including one or more payment processing companies. By
                        using the services, you consent to the involvement of
                        other companies in facilitating the acceptance of your
                        payment.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Intellectual Property Rights.</b> All logos,
                        trademarks, service marks, and trade dress, text,
                        images, copyrights, photographs, software, audio files,
                        and all related intellectual property rights associated
                        with the Services belong exclusively to the Company. You
                        do not obtain any rights in any of this intellectual
                        property by virtue of accessing the Services, and the
                        Company does not grant you any rights of ownership in
                        the same. Instead, the Company grants you only the
                        limited, revocable license to access the Services for
                        the purpose of submitting payment credentials. You may
                        not imitate the Company's Services, or decompile, copy,
                        modify, or reverse engineer the Services in whole or in
                        part, on your own behalf or on behalf of anyone else.
                        You may not tamper with or damage, or attempt to tamper
                        with or damage, any aspect of the Services. You may not
                        sell or resell the Services, subject to them to data
                        mining or extraction tools, or plant any malware,
                        viruses, or harmful or malicious code in the Services.
                        Further, you may not disable or circumvent, or attempt
                        to disable or circumvent, any security features of the
                        Services.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Privacy Policy.</b> Please separately review the
                        paymycar.com <Link to="/privacy">Privacy Policy</Link>,
                        which also governs your use of the Services. The terms
                        of the Privacy Policy are incorporated into these Terms
                        in full.
                    </p>
                </li>
                <li>
                    <p>
                        <b>DISCLAIMER OF WARRANTIES.</b> THE COMPANY PROVIDES
                        THE SERVICES ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE
                        COMPANY DISCLAIMS ALL WARRANTIES WHATSOEVER ASSOCIATED
                        WITH THE SERVICES, INCLUDING BOTH EXPRESS AND IMPLIED
                        WARRANTIES, WARRANTIES OF FITNESS FOR A PARTICULAR
                        PURPOSE OR WARRANTIES OR MERCHANTABILITY. FURTHER, THE
                        COMPANY DISCLAIMS ANY WARRANTY THAT THE SERVICES WILL BE
                        ERROR-FREE, VIRUS-FREE, OR UNINTERRUPTED. THE COMPANY
                        MAKES NO WARRANTY AS TO THE SECURITY OF ANY DATA
                        TRANSMITTED USING THE SERVICES. YOU ACKNOWLEDGE THAT YOU
                        ARE USING THE SERVICES SOLELY AT YOUR OWN RISK.
                    </p>
                </li>
                <li>
                    <p>
                        <b>LIMITATION OF LIABILITY.</b> IF YOU ARE DISPLEASED
                        WITH THE SERVICES OR THE COMPANY, YOUR SOLE REMEDY WILL
                        BE TO DISCONTINUE USE OF THE SERVICES, AND THE COMPANY
                        SHALL NOT BE LIABLE TO YOU FOR ANY MONEY DAMAGES.
                        HOWEVER, IF THE COMPANY'S DISCLAIMER OF MONETARY
                        LIABILITY TO YOU IS FOR ANY REASON UNENFORCEABLE UNDER
                        APPLICABLE LAW, THEN THE COMPANY'S LIABILITY TO YOU
                        SHALL BE LIMITED TO THE PAYMENT OF ONLY COMPENSATORY
                        DAMAGES CAPPED AT THE LESSER OF (A) TWO TIMES THE AMOUNT
                        OF THE LAST PAYMENT YOU MADE, OR ATTEMPTED TO MAKE,
                        USING THE SERVICES OR (B) $2,000 (U.S. DOLLARS). IN NO
                        EVENT SHALL THE COMPANY BE LIABLE TO YOU FOR ANY
                        SPECIAL, CONSEQUENTIAL, INDIRECT, PUNITIVE, OR EXEMPLARY
                        DAMAGES, INCLUDING FOR LOST BUSINESS VALUE OR LOST
                        PROFITS, REGARDLESS OF WHETHER SUCH DAMAGES WERE
                        FORESEEABLE OR WHETHER ANY PARTY WAS ADVISED OF THE
                        POSSIBILITY OF THE SAME.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Amendment and Termination.</b> The Company reserves
                        the right to amend these Terms at any time without
                        notice. The amended Terms will take effect immediately
                        upon posting to the website, and will supersede previous
                        versions of the Terms. The Company also reserves the
                        right to terminate access to all or any part of the
                        Services at any time, without or without notice to you,
                        or to terminate your access to the Services at any time,
                        for any reason in its sole discretion.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Choice of Law.</b> This Agreement shall be governed
                        by and construed according to the laws of the State of
                        Oregon without regard to conflicts of law principles.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Dispute Resolution.</b> You agree that any disputes
                        arising out of or relating in any way to the Services,
                        or your use of the Services (including through a
                        business partner affiliated with the Company) will be
                        resolved exclusively through binding arbitration before
                        the American Arbitration Association ("AAA") pursuant to
                        the Federal Arbitration Act. The arbitrator's
                        jurisdiction shall be co-extensive with that of a court
                        of appropriate jurisdiction and shall encompass the
                        authority to interpret the scope of this arbitration
                        clause and the arbitrability of any particular dispute.
                        The proceedings before the AAA shall be initiated in
                        Portland, Oregon, or if you prefer, may be initiated by
                        you in the AAA office your state of residence. The
                        arbitration shall take place before a single arbitrator,
                        mutually selected by the parties, or if the parties
                        cannot agree on an arbitrator, one appointed by the AAA.
                        No party in arbitration shall be liable to the other for
                        any punitive, exemplary, special, consequential, or
                        indirect damages, including lost profits, or lost
                        business value, even if such damages were foreseeable,
                        or if the other party were specifically apprised of the
                        possibility of such damages.{' '}
                        <strong>
                            You may only resolve disputes with the Company on an
                            individual basis, and not on a collective or class
                            basis. Class arbitrations, consolidated
                            arbitrations, class actions, and private attorney
                            general actions are prohibited and you waive your
                            right to participate in the same.
                        </strong>{' '}
                        The decision of the arbitrator shall be final and
                        binding on the parties and enforceable in any court
                        having appropriate jurisdiction.
                        <p>
                            In one limited exception to the parties' agreement
                            to arbitrate, either party may opt to bring suit in
                            court (as specific below) solely for the purpose of
                            obtaining injunctive relief. Other than deciding
                            petitions for injunctive relief or enforcing
                            judgments entered in arbitration, you and the
                            Company agree that no court shall have jurisdiction
                            to decide any other disputes between you. Should you
                            or the Company opt to initiate an action for
                            injunctive relief, you agree to do so in courts of
                            appropriate jurisdiction within Portland, Oregon,
                            and you each consent to personal jurisdiction before
                            such courts.
                        </p>
                    </p>
                </li>
                <li>
                    <p>
                        <b>Customer Service.</b> For any questions about these
                        Terms, the Privacy Policy, or the Services, please email{' '}
                        <a href="mailto:support@paymycar.com">
                            support@paymycar.com
                        </a>
                        . For questions about your payment arrangements,
                        including any requests for refunds, please contact your
                        dealership directly.
                    </p>
                </li>
            </ol>
        </section>
    </PageWrapper>
)

export default LegalPage
