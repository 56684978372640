import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDealership } from 'DealershipContext'
import { useUser } from 'UserContext'
import { errorKind } from 'services/api'
import { formatMoney } from 'services/fmt'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import Card from 'components/Card'
import Panel from 'components/Panel'
import Spinner from 'components/Spinner'
import Link from 'components/Link'
import useApi from 'components/UseApi'
import VehicleDue from 'components/VehicleDue'
import VehicleUpdated from 'components/VehicleUpdated'
import PaymentForm from './components/PaymentForm'
import ContactDealership from 'components/ContactDealership'
import AccountDetails from './components/AccountDetails'
import ScheduledPayments from 'components/ScheduledPayments'
import styles from './Vehicle.module.css'

const VehiclePage = ({ match }) => {
    // Extract contract ID from the route parameters.
    const contractID = match.params.contractid

    // Use some context.
    const { dealership } = useDealership()
    const { user, setUser } = useUser()

    // State to handle redirecting to another page.
    const [redirect, setRedirect] = useState()

    // API calls for necessary information.
    const [contract, refetchContract] = useApi({
        method: 'get',
        endpoint: `/contracts/${contractID}`
    })
    const [paymentMethods, refetchPaymentMethods] = useApi({
        method: 'get',
        endpoint: `/payment-methods?user_id=${user.id}&dealership_id=${dealership.id}`
    })
    const [payments, refetchPayments] = useApi({
        method: 'get',
        endpoint: `/payments?contract_id=${contractID}&order=created_desc`
    })

    // Handle redirect being set to true.
    if (redirect) {
        return <Redirect push to={redirect} />
    }

    // Handle errors with any of the API calls.
    if (contract.error || paymentMethods.error || payments.error) {
        let error
        if (contract.error) {
            error = contract.error
        } else if (paymentMethods.error) {
            error = paymentMethods.error
        } else {
            error = payments.error
        }

        // Handle kinds of errors.
        switch (error.kind) {
            case errorKind.REAUTHENTICATION_REQUIRED:
                // The error is the unauthorized error, change the user to no user.
                // (This should redirect us and cause this component to be unmounted.)
                setUser(null)
                return (
                    <PageWrapper>
                        <Spinner />
                    </PageWrapper>
                )
            case errorKind.NOT_FOUND:
            case errorKind.PERMISSION_DENIED:
                // These errors indicate the user is attempting to view a vehicle they're not associated with.
                // Throw the user back to their home page.
                return <Redirect to={'/'} />
            default:
                throw error
        }
    }

    // Handle loading state for any of the API calls.
    if (contract.loading || paymentMethods.loading || payments.loading) {
        return (
            <PageWrapper>
                <Spinner />
            </PageWrapper>
        )
    }

    // Prep contents for the <Panel>, and also determine whether to show delete button
    // for scheduled payments.
    let panelContents
    if (contract.data.awaiting_update) {
        // The below should rarely (or hopefully never) actually be used, but we
        // define it as a fallback in case the contract is marked as awaiting
        // update and we have no related payment information. This should
        // probably not happen because the awaiting update flag is dependent on
        // the presence of a related unimported payment.
        panelContents = (
            <>
                <p>A payment has yet to be acknowledged by the dealership.</p>
                <p>
                    Account details for this vehicle will be unavailable until
                    all payments have been acknowledged.
                </p>
            </>
        )

        const lastPayment = payments.data?.length > 0 ? payments.data[0] : null
        if (lastPayment && !lastPayment.is_imported) {
            panelContents = (
                <>
                    <p>
                        The latest payment of {formatMoney(lastPayment.amount)}{' '}
                        was submitted successfully.
                    </p>
                    <p>Updated account details will be available soon.</p>
                </>
            )
        }
    } else if (contract.data.payments_disabled) {
        panelContents = (
            <>
                <p>
                    {dealership.business_name} has disabled online payments for
                    this vehicle.
                </p>
                <ContactDealership />
            </>
        )
    } else if (paymentMethods.data && paymentMethods.data.length === 0) {
        panelContents = (
            <p>
                <Link
                    to="/new-payment-method"
                    className={styles.addPaymentMethodLink}
                >
                    Add Payment Method
                </Link>
            </p>
        )
    } else {
        panelContents = (
            <PaymentForm
                contract={contract.data}
                paymentMethods={paymentMethods.data}
                onCancel={(refresh) => {
                    if (refresh) {
                        // Refetch all information related to a payment.
                        refetchContract()
                        refetchPaymentMethods()
                        refetchPayments()
                    } else {
                        // If the payment form is displayed, the user likely
                        // came from the root (vehicles) page.
                        setRedirect('/')
                    }
                }}
                onSuccess={() => {
                    // Refetch information affected by the new payment.
                    refetchContract()
                    refetchPayments()
                }}
            />
        )
    }

    const updated = <VehicleUpdated contract={contract.data} />
    return (
        <PageWrapper>
            <Card topDetail={updated} className={styles.card}>
                <div className={styles.cardContent}>
                    <PageTitle className={styles.title}>
                        {contract.data.model_year} {contract.data.make}{' '}
                        {contract.data.model}
                    </PageTitle>
                    {dealership.payment_fee !== 0 && (
                        <p className={styles.notice}>
                            A convenience fee of {formatMoney(dealership.payment_fee)} will
                            be added to all one-time credit card and ACH
                            transactions for which the customer is not present
                            at the dealership. If you do not wish to pay this
                            fee, please pay in person or send a check by mail.
                            By continuing with this transaction, you agree to
                            the convenience fee.
                        </p>
                    )}
                    <VehicleDue
                        contract={contract.data}
                        className={styles.due}
                    />
                    <ScheduledPayments 
                        contractID={contractID}
                    />
                    <Panel className={styles.panel}>{panelContents}</Panel>
                    {!contract.data.awaiting_update && (
                        <AccountDetails
                            contract={contract.data}
                            lastPayment={
                                payments.data && payments.data.length > 0
                                    ? payments.data[0]
                                    : null
                            }
                        />
                    )}
                </div>
            </Card>
            {contract.data.payments_disabled ? (
                <Link to="/past-vehicles" button={true}>
                    Back to Past Vehicles
                </Link>
            ) : (
                <Link to="/" button={true}>
                    Back to Vehicles
                </Link>
            )}
        </PageWrapper>
    )
}

VehiclePage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            contractid: PropTypes.string
        })
    }).isRequired
}

export default VehiclePage
