import React from 'react'
import { Redirect } from 'react-router-dom'
import { useUser } from 'UserContext'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import LabeledInput from 'components/LabeledInput'
import useApi from 'components/UseApi'
import Form from 'components/Form'

/**
 * Recover password page, with a form for the user to use to request a password reset.
 */
const RecoverPasswordPage = () => {
    const { user } = useUser()

    const [email, setEmail] = React.useState(user ? user.email : '')
    const [disabled, setDisabled] = React.useState(false)
    const [error, setError] = React.useState()
    const [done, setDone] = React.useState(false)
    const [cancel, setCancel] = React.useState(false)

    const [resetPassword, doResetPassword] = useApi(null, 'pre-request') // We initialize data with a sentinel in order to detect success (since on success no data is returned).

    // Hook to handle API responses.
    React.useEffect(() => {
        if (resetPassword.loading) {
            return
        }

        if (resetPassword.error) {
            setError(resetPassword.error.message)
            setDisabled(false)
            return
        }

        // We need to make sure this is post-request, and not the initial execution prior to the request being fired.
        // On success, no data is returned meaning data should be null.
        if (resetPassword.data === null) {
            setDone(true)
        }
    }, [resetPassword])

    if (cancel) {
        return <Redirect push to="/" />
    }

    if (done) {
        return (
            <PageWrapper>
                <p>Recover password request submitted.</p>
                <p>
                    You will receive an email with more instructions if an
                    account exists for {email}
                </p>
            </PageWrapper>
        )
    }

    const handleSubmit = () => {
        setDisabled(true)
        setError('')

        doResetPassword({
            method: 'post',
            endpoint: '/reset-password',
            body: { email }
        })
    }

    return (
        <PageWrapper>
            <PageTitle>Recover Password</PageTitle>
            <p>
                To recover your password, please enter the email address
                associated with your account.
            </p>
            <Form
                disabled={disabled}
                onSubmit={handleSubmit}
                onCancel={() => setCancel(true)}
                error={error}
            >
                <LabeledInput
                    label="Email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    disabled={disabled}
                />
            </Form>
        </PageWrapper>
    )
}

export default RecoverPasswordPage
