import PropTypes from 'prop-types'
import loader from './images/spinner.svg'
import styles from './Spinner.module.css'
import { formatClassNames } from 'services/fmt'

/**
 * A loading spinner for use when we're waiting for something to happen.
 */
const Spinner = ({ className, ...rest }) => (
    <div className={formatClassNames([styles.spinner, className])} {...rest}>
        <img src={loader} alt="loading..." />
    </div>
)

Spinner.propTypes = {
    className: PropTypes.string
}

export default Spinner
