import PropTypes from 'prop-types'
import { userPropType } from 'proptypes'
import * as Sentry from '@sentry/browser'
import config from 'configs/sentry'
import PayMyCarLogo from 'components/PayMyCarLogo'
import styles from './Error.module.css'

/**
 * Enumerated error levels recognized by sentry.
 */
const errorLevel = {
    FATAL: 'fatal',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    DEBUG: 'debug'
}

/**
 * An error page.
 *
 * @param {Error|Object} err - The error that brought us here.
 * @param {Object} [user] - User logged in when the error occurred.
 * @param {Array} [tags] - Array of tags associated with the error, where each element pair is a key followed by a value.
 * @param {Array} [extra] - Array of extra information associated with the error, where each element pair is a key followed by a value.
 */
const ErrorPage = ({ err, user, tags, extra }) => {
    // Try to report the error to sentry.
    let reported = true // Will remain true if we managed to report to sentry.
    let e // This will be the error we actually report.
    try {
        Sentry.withScope((scope) => {
            // Add tags that should always be present.
            scope.setTag('environment', config.environment)

            // Add supplied user, tags and extra info.
            if (user) {
                scope.setUser(user)
            }
            setTags(tags, scope.setTag)
            setTags(extra, scope.setExtra)

            // We need an error to report.
            if (err instanceof Error) {
                // App-internal error
                e = err
                scope.setTag('internal', true)
                scope.setLevel(errorLevel.ERROR)
            } else {
                // Undesired or unhandled API error
                e = new Error(err.message)
                e.name = err.kind
                scope.setTag('internal', false)
                scope.setLevel(errorLevel.WARNING)
            }

            // Fingerprint errors less aggressively to avoid distinct issues
            // just because the stack trace differed slightly.
            scope.setFingerprint([
                '{{ function }}',
                '{{ type }}',
                '{{ module }}',
                '{{ package }}',
                e.name,
                e.message
            ])

            Sentry.captureException(e)
        })
    } catch (sentryErr) {
        // Failed to report the error.
        reported = false

        // Log all available information about the error in case the user calls.
        console.log(err)
        if (tags) {
            console.log(tags)
        }
        if (extra) {
            console.log(extra)
        }
        console.error(sentryErr)
    }

    let supportEmailLink = encodeURI(
        `mailto:support@paymycar.com?subject=Support Request&body=PayMyCar encountered an error.\n\n\n---\nError details:\n\n${e}`
    )

    return (
        <div className={styles.wrapper}>
            <header className={styles.header}>
                <a href="/">
                    <PayMyCarLogo width="400" />
                </a>
            </header>
            <p className={styles.text}>Sorry! Something went wrong...</p>
            {reported ? (
                <>
                    <p className={styles.text}>
                        Support has been notified and will resolve as soon as
                        possible.
                    </p>
                    <p className={styles.text}>
                        Until then, please refresh the page and try again.
                    </p>
                </>
            ) : (
                <p className={styles.text}>
                    Please refresh the page and try again, or contact support at{' '}
                    <a href={supportEmailLink}>support@paymycar.com</a>
                </p>
            )}
        </div>
    )
}

ErrorPage.propTypes = {
    err: PropTypes.oneOfType([
        PropTypes.instanceOf(Error),
        PropTypes.shape({
            kind: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired
        })
    ]).isRequired,
    user: userPropType,
    tags: PropTypes.array,
    extra: PropTypes.array
}

/**
 * Helper function that calls f with a pair of arguments taken from the tags array.
 */
const setTags = (tags, f) => {
    if (tags && tags.length > 1) {
        for (let i = 0; i < tags.length - 1; i += 2) {
            f(tags[i], tags[i + 1])
        }
    }
}

export default ErrorPage
