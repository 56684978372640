import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    formatDateMMDDYYYY,
    formatMoney,
    formatClassNames
} from 'services/fmt'
import { parseDateUnix } from 'services/date'
import useApi from 'components/UseApi'

/**
 * A <p> element containing the amount and date of the the next scheduled payment
 * Handles its' own API call and sorts through scheduled payments to get the
 * next scheduled payment
 */
const NextScheduledPayment = ({ contractID, className, ...rest }) => {
    // API Call to get scheduled transactions
    const [scheduledPayments] = useApi({
        method: 'get',
        endpoint: `/transactions/${contractID}`
    })
    // It's possible for multiple scheduled payments to exist, however
    // currently we only want to display the soonest scheduled payment.
    const [payment, setPayment] = useState(null) // amount and date

    // We only want to do this whenever scheduledPayments change 
    // (including the first time component renders)
    useEffect(() => {
        // Determine if we have data
        if (!Array.isArray(scheduledPayments.data)) {
            // No scheduled payment, render nothing
            setPayment(null)
        } else {
            // Do we even have any data in the array?
            if (scheduledPayments.data.length < 1) {
                // No
                setPayment(null)
                return
            }
            // We have at least 1 scheduled payment, find the earliest
            let earliestpayment = scheduledPayments.data.reduce(
                (earliest, payment) => {
                    if (earliest?.scheduled_payment_date < payment?.scheduled_payment_date) {
                        return earliest
                    }
                    return payment
                }
            )
            // Format values and update our state
            let d = parseDateUnix(earliestpayment.scheduled_payment_date)
            setPayment({
                date: formatDateMMDDYYYY(d),
                amount: formatMoney(earliestpayment.amount)
            })
        }
    }, [scheduledPayments])
    
    return (
        <>
            { payment != null ? (
                <p className={formatClassNames([className])} {...rest}>
                    {payment.amount} payment scheduled for {payment.date}
                </p>
            ) : null}
        </> 
    )
}

NextScheduledPayment.propTypes = {
    contractID: PropTypes.any,
    className: PropTypes.string,
}

export default NextScheduledPayment
