import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useUser } from 'UserContext'
import { useDealership } from 'DealershipContext'
import { errorKind } from 'services/api'
import useApi from 'components/UseApi'
import Spinner from 'components/Spinner'
import Card from 'components/Card'
import Link from 'components/Link'
import VehicleDue from 'components/VehicleDue'
import VehicleUpdated from 'components/VehicleUpdated'
import styles from './Vehicles.module.css'
import Heading from 'components/Heading'
import NextScheduledPayment from 'components/NextScheduledPayment'

/**
 * The collection of all vehicles (contracts) the logged-in user is associated with.
 *
 * @param {boolean} [disabled=false] - If true only disabled vehicles will be displayed, otherwise only enabled.
 */
const Vehicles = ({ disabled = false }) => {
    const { dealership } = useDealership()
    const { user, setUser } = useUser()

    // State to handle redirects to other pages.
    const [redirect, setRedirect] = React.useState()

    const [contracts] = useApi({
        method: 'get',
        endpoint: `/contracts?dealership_id=${dealership.id}&user_id=${user.id}`,
        transformData: (data) => {
            return data.filter(
                (contract) => contract.payments_disabled === disabled
            )
        }
    })

    // Handle redirect.
    if (redirect) {
        return <Redirect push to={redirect} />
    }

    // Handle errors.
    if (contracts.error) {
        // If the error is the unauthorized error, change the user to no user.
        // (This should redirect us and cause this component to be unmounted.)
        if (contracts.error.kind === errorKind.REAUTHENTICATION_REQUIRED) {
            setUser(null)
            return <Spinner />
        }

        throw contracts.error
    }

    // Display a spinner until the API request completes.
    if (contracts.loading) {
        return <Spinner />
    }

    // Handle no vehicles.
    if (contracts.data.length === 0) {
        return (
            <>
                <p>No vehicles found.</p>
                {disabled ? (
                    <Link to="/" button={true}>
                        Go To Current Vehicles
                    </Link>
                ) : (
                    <Link to="/past-vehicles" button={true}>
                        Go To Past Vehicles
                    </Link>
                )}
            </>
        )
    }

    // Display list of vehicles.
    const vehicles = contracts.data.map((contract) => {
        const updated = <VehicleUpdated contract={contract} />
        return (
            <li key={contract.id} className={styles.contract}>
                <Card
                    topDetail={updated}
                    className={styles.contractCard}
                    onClick={() => {
                        setRedirect(`/vehicle/${contract.id}`)
                    }}
                >
                    <div className={styles.contractCardContent}>
                        <Heading size="md">{`${contract.model_year} ${contract.make} ${contract.model}`}</Heading>
                        <VehicleDue
                            contract={contract}
                            className={styles.contractDue}
                        />
                        <NextScheduledPayment 
                            contractID={contract.id}
                            className={styles.scheduledPayment}
                        />
                        {contract.payments_disabled && (
                            <p className={styles.paymentsDisabled}>
                                Online Payments Disabled
                            </p>
                        )}
                        <Link
                            to={`/vehicle/${contract.id}`}
                            className={styles.contractDetails}
                        >
                            View Details
                        </Link>
                    </div>
                </Card>
            </li>
        )
    })

    return <ul className={styles.contractList}>{vehicles}</ul>
}

Vehicles.propTypes = {
    disabled: PropTypes.bool
}

export default Vehicles
