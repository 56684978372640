import React from 'react'
import { Redirect } from 'react-router-dom'
import { useUser } from 'UserContext'
import { errorKind } from 'services/api'
import PageWrapper from 'components/PageWrapper'
import Spinner from 'components/Spinner'
import useApi from 'components/UseApi'

/**
 * Logout page, which logs out a user.
 */
const LogoutPage = () => {
    const { user, setUser } = useUser()

    const [logout] = useApi(() => {
        // Only make the request is there's a logged-in user.
        if (!user) {
            return
        }

        return {
            method: 'get',
            endpoint: '/logout'
        }
    })

    // Hook to handle logout success.
    React.useEffect(() => {
        // No need to do anything if user isn't logged in, or if the request is still loading or resulted in an error.
        if (!user || logout.loading || logout.error) {
            return
        }

        // If we've made it here, then a user was once logged-in and now is not.
        setUser(null)
    }, [user, setUser, logout])

    if (logout.error) {
        // Disregard errors related to authentication (we'll assume the log out was successful).
        if (logout.error.kind === errorKind.REAUTHENTICATION_REQUIRED) {
            setUser(null)
        } else {
            throw logout.error
        }
    }

    return (
        <PageWrapper>
            {user ? <Spinner /> : <Redirect to="/login" />}
        </PageWrapper>
    )
}

export default LogoutPage
