import PropTypes from 'prop-types'
import styles from './Overlay.module.css'

/**
 * Overlay is a full-screen semi-transparent overlay with centered children inside a container.
 */
const Overlay = ({ children }) => (
    <div className={styles.overlay}>
        <div className={styles.contents}>{children}</div>
    </div>
)

Overlay.propTypes = {
    children: PropTypes.node
}

export default Overlay
