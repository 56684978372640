import Link from 'components/Link'
import styles from './ForgotPasswordLink.module.css'

/**
 * Link to the recover password page.
 */
const ForgotPasswordLink = () => (
    <Link to="/recover-password" className={styles.link}>
        Forgot Password?
    </Link>
)

export default ForgotPasswordLink
