/**
 * Auto-generated, do not edit.
 * 
 * To adjust config, change `build-configs.js` in the project root.
 */

const cfg = {
  dsn: 'https://7f1ab953afa748848217e4ecc2a2fdd2@o32398.ingest.sentry.io/5271590',
  environment: 'production',
  release: 'paymycar-webapp@v1.10.1'
};

export default cfg
