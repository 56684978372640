import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import Vehicles from 'components/Vehicles'

/**
 * The past vehicles page, where vehicles with payments disabled are displayed.
 */
const PastVehiclesPage = () => {
    return (
        <PageWrapper>
            <PageTitle>Past Vehicles</PageTitle>
            <Vehicles disabled={true} />
        </PageWrapper>
    )
}

export default PastVehiclesPage
