import React from 'react'
import { Redirect } from 'react-router-dom'
import { userPropType, apiErrorPropType } from 'proptypes'
import { useUser } from 'UserContext'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import LabeledInput from 'components/LabeledInput'
import Link from 'components/Link'
import useApi from 'components/UseApi'
import Form from 'components/Form'
import styles from './Register.module.css'

/**
 * Register page, which handles new user registration.
 */
const RegisterPage = ({ tokenUser, tokenError }) => {
    const { setUser } = useUser()

    const [name, setName] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [agreeTerms, setAgreeTerms] = React.useState(false)
    const [disabled, setDisabled] = React.useState(false)
    const [error, setError] = React.useState('')
    const [redirect, setRedirect] = React.useState(false)

    const [updateUser, doUpdateUser] = useApi()

    // Hook to handle token results.
    React.useEffect(() => {
        if (!tokenError) {
            setName(tokenUser.nickname)
            return
        }

        setDisabled(true)
        setError(
            'Registration link expired, please use the Forgot Password form to get a new link. You will be redirected momentarily...'
        )
        const redirectTimeoutID = window.setTimeout(() => {
            setRedirect(true)
        }, 5000)

        return () => {
            window.clearTimeout(redirectTimeoutID)
        }
    }, [tokenUser, tokenError])

    // Hook to handle API responses.
    React.useEffect(() => {
        if (updateUser.loading) {
            return
        }

        if (updateUser.error) {
            setError(updateUser.error.message)
            setDisabled(false)
            return
        }

        if (updateUser.data) {
            setUser(updateUser.data)
            // Should be navigated away from the RegisterPage because the App state was updated with a user.
        }
    }, [updateUser, setUser])

    if (redirect) {
        return <Redirect push to="/recover-password" />
    }

    const handleSubmit = () => {
        if (password !== confirmPassword) {
            setError("Passwords don't match, try again.")
            return
        }

        setDisabled(true)
        setError('')

        doUpdateUser({
            method: 'put',
            endpoint: `/users/${tokenUser.id}`,
            body: {
                email: tokenUser.email,
                nickname: name,
                password: password
            },
            chain: () => {
                // User successfully updated.
                // Automatically reauthenticate using previously entered password.
                return {
                    method: 'post',
                    endpoint: '/authenticate/web',
                    body: {
                        email: tokenUser.email,
                        password: password
                    }
                }
            }
        })
    }

    return (
        <PageWrapper>
            <PageTitle>Welcome to PayMyCar!</PageTitle>
            <p>
                Please complete the following form to finish registering your
                account:
            </p>
            <Form disabled={disabled} onSubmit={handleSubmit} error={error}>
                <LabeledInput
                    label="Email"
                    type="email"
                    required
                    value={tokenUser ? tokenUser.email : ''}
                    disabled
                />
                <LabeledInput
                    label="Name"
                    required
                    minLength="2"
                    maxLength="100"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    disabled={disabled}
                />
                <LabeledInput
                    label="Password"
                    type="password"
                    required
                    minLength="6"
                    maxLength="32"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                    disabled={disabled}
                />
                <LabeledInput
                    label="Confirm Password"
                    type="password"
                    required
                    minLength="6"
                    maxLength="32"
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value)
                    }}
                    disabled={disabled}
                />
                <LabeledInput
                    className={styles.terms}
                    label={
                        <>
                            I agree to the PayMyCar{' '}
                            <Link to="/legal" target="_blank">
                                terms and conditions
                            </Link>
                            .
                        </>
                    }
                    type="checkbox"
                    required
                    checked={agreeTerms}
                    onChange={(e) => {
                        setAgreeTerms(e.target.checked)
                    }}
                    disabled={disabled}
                />
            </Form>
        </PageWrapper>
    )
}

RegisterPage.propTypes = {
    tokenUser: userPropType,
    tokenError: apiErrorPropType
}

export default RegisterPage
