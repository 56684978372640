import React from 'react'
import PropTypes from 'prop-types'
import Panel from 'components/Panel'
import styles from './Message.module.css'
import { formatClassNames } from 'services/fmt'

/**
 * Message is a dismissable message.
 *
 * Note that the message is not undismissed if children change. In order to
 * undimiss, the component should be conditionally rendered so that it's
 * unmounted before any potential change to the children, like:
 * `{error && <Message isError={true}>{children}</Message>}`
 *
 * @param {boolean} isError - True if the message is an error message. Affects styling.
 */
const Message = ({ isError, children }) => {
    const [dismissed, setDismissed] = React.useState(false)

    if (dismissed) {
        return null
    }

    return (
        <Panel
            className={formatClassNames([
                styles.panel,
                isError && styles.error
            ])}
        >
            <aside>
                {children}
                <button
                    type="button"
                    onClick={() => {
                        setDismissed(true)
                    }}
                    className={styles.dismissButton}
                >
                    ×
                </button>
            </aside>
        </Panel>
    )
}

Message.propTypes = {
    isError: PropTypes.bool,
    children: PropTypes.node
}

export default Message
