import { useDealership } from 'DealershipContext'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import BusinessHours from './components/BusinessHours'
import styles from './Contact.module.css'

/**
 * Contact page with dealership information.
 */
const ContactPage = () => {
    const { dealership } = useDealership()

    return (
        <PageWrapper>
            <PageTitle>{dealership.business_name}</PageTitle>
            <div className={styles.contactInfo}>
                {dealership.address && <p>{dealership.address}</p>}
                {dealership.phone && (
                    <p>
                        <a href={`tel:${dealership.phone}`}>
                            {dealership.phone}
                        </a>
                    </p>
                )}
                {dealership.email && (
                    <p>
                        <a href={`mailto:${dealership.email}`}>
                            {dealership.email}
                        </a>
                    </p>
                )}
            </div>
            {dealership.business_hours && (
                <BusinessHours hours={dealership.business_hours} />
            )}
        </PageWrapper>
    )
}

export default ContactPage
