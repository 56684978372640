import PropTypes from 'prop-types'
import logoBlack from './images/PMC.png'
import logoWhite from './images/PMC_w.png'

const PayMyCarLogo = ({ light, ...rest }) => {
    if (light) {
        return <img src={logoWhite} alt="PayMyCar" {...rest} />
    }

    return <img src={logoBlack} alt="PayMyCar" {...rest} />
}

PayMyCarLogo.propTypes = {
    light: PropTypes.bool
}

export default PayMyCarLogo
