import PropTypes from 'prop-types'

export const apiErrorPropType = PropTypes.shape({
    kind: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
})

export const businessHoursPropType = PropTypes.arrayOf(
    PropTypes.shape({
        open: PropTypes.number.isRequired,
        close: PropTypes.number.isRequired
    })
)

export const paymentRestrictionPropType = PropTypes.shape({
    kind: PropTypes.string.isRequired,
    flat_amount: PropTypes.number
})

export const dealershipPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    dms_dealership_id: PropTypes.string.isRequired,
    subdomain: PropTypes.string.isRequired,
    business_name: PropTypes.string.isRequired,
    description: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    timezone: PropTypes.string.isRequired,
    business_hours: businessHoursPropType,
    style_template: PropTypes.shape({
        wrapper: PropTypes.string,
        main: PropTypes.string
    }),
    payment_processor: PropTypes.string.isRequired,
    min_payment: paymentRestrictionPropType,
    max_payment: paymentRestrictionPropType,
    is_disabled: PropTypes.bool.isRequired,
    allow_ach: PropTypes.bool.isRequired,
    created: PropTypes.number.isRequired,
    modified: PropTypes.number.isRequired
})

export const userPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    nickname: PropTypes.string,
    created: PropTypes.number.isRequired,
    modified: PropTypes.number.isRequired
})

export const contractPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    dealership_id: PropTypes.number.isRequired,
    dms_contract_id: PropTypes.string.isRequired,
    payments_disabled: PropTypes.bool.isRequired,
    model_year: PropTypes.number,
    make: PropTypes.string.isRequired,
    model: PropTypes.string,
    vin: PropTypes.string,
    balance: PropTypes.number,
    payoff: PropTypes.number,
    regular_payment_amount: PropTypes.number,
    due_amount: PropTypes.number,
    due_date: PropTypes.string,
    message: PropTypes.string,
    awaiting_update: PropTypes.bool.isRequired,
    created: PropTypes.number.isRequired,
    modified: PropTypes.number.isRequired
})

export const paymentMethodPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    dealership_id: PropTypes.number.isRequired,
    nickname: PropTypes.string,
    kind: PropTypes.string,
    provider: PropTypes.string,
    last_4: PropTypes.string,
    owner_last_name: PropTypes.string,
    owner_first_name: PropTypes.string,
    owner_middle_name: PropTypes.string,
    owner_is_business: PropTypes.bool,
    expiration_year: PropTypes.number,
    expiration_month: PropTypes.number,
    created: PropTypes.number.isRequired,
    modified: PropTypes.number.isRequired
})

export const paymentPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    processor_payment_id: PropTypes.string.isRequired,
    contract_id: PropTypes.number.isRequired,
    dms_contract_id: PropTypes.string.isRequired,
    payment_method_info: PropTypes.shape({
        owner_last_name: PropTypes.string,
        owner_first_name: PropTypes.string,
        owner_middle_name: PropTypes.string,
        owner_is_business: PropTypes.bool,
        kind: PropTypes.string,
        provider: PropTypes.string,
        last_4: PropTypes.string,
        expiration_year: PropTypes.number,
        expiration_month: PropTypes.number
    }).isRequired,
    amount: PropTypes.number,
    processed: PropTypes.number,
    is_imported: PropTypes.bool,
    receipt_info: PropTypes.string,
    created: PropTypes.number.isRequired,
    modified: PropTypes.number.isRequired
})

export const scheduledPaymentsPropType = PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number.isRequired,
    fee: PropTypes.number,
    scheduled_payment_date: PropTypes.number.isRequired,
    payment_method_kind: PropTypes.string,
    payment_method_last4: PropTypes.string,
    payment_method_expiration_month: PropTypes.number,
    payment_method_expiration_year: PropTypes.number
}))
