// Cross-session storage.
const storage = window.localStorage

/**
 * Saves the persist login value.
 * @param {boolean} persist - True if we should persist login across sessions.
 */
export const setPersistLogin = (persist) => {
    storage.setItem('persistLogin', persist)
}

/**
 * Retrieves the saved persist login value.
 * @returns {boolean} The saved persist login value. Always false if no value is saved.
 */
export const getPersistLogin = () => {
    const persist = storage.getItem('persistLogin')
    return persist === 'true'
}
