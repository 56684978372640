import React from 'react'
import { Redirect } from 'react-router-dom'
import { userPropType, apiErrorPropType } from 'proptypes'
import { useUser } from 'UserContext'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import LabeledInput from 'components/LabeledInput'
import useApi from 'components/UseApi'
import Form from 'components/Form'

/**
 * Recover page, which handles password recovery.
 */
const RecoverPage = ({ tokenUser, tokenError }) => {
    const { setUser } = useUser()

    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [disabled, setDisabled] = React.useState(false)
    const [error, setError] = React.useState('')
    const [redirect, setRedirect] = React.useState(false)
    const [done, setDone] = React.useState(false)

    const [recover, doRecover] = useApi()

    // Hook to handle a token error.
    React.useEffect(() => {
        if (!tokenError) {
            return
        }

        setDisabled(true)
        setError(
            'Recovery link expired, please use the Forgot Password form to get a new link. You will be redirected momentarily...'
        )
        const redirectTimeoutID = window.setTimeout(() => {
            setRedirect(true)
        }, 5000)

        return () => {
            window.clearTimeout(redirectTimeoutID)
        }
    }, [tokenError])

    // Hook to handle API responses.
    React.useEffect(() => {
        if (recover.loading) {
            return
        }

        if (recover.error) {
            setError(recover.error.message)
            setDisabled(false)
            return
        }

        if (recover.data) {
            setUser(recover.data)
            setDone(true)
        }
    }, [recover, setUser])

    if (redirect) {
        return <Redirect push to="/recover-password" />
    }

    if (done) {
        return <Redirect push to="/" />
    }

    const handleSubmit = () => {
        if (password !== confirmPassword) {
            setError("Passwords don't match, try again.")
            return
        }

        setDisabled(true)

        doRecover({
            method: 'put',
            endpoint: `/users/${tokenUser.id}`,
            body: {
                email: tokenUser.email,
                nickname: tokenUser.nickname,
                password: password
            },
            chain: () => {
                // User updated, automatically login.
                return {
                    method: 'post',
                    endpoint: '/authenticate/web',
                    body: {
                        email: tokenUser.email,
                        password: password,
                        persist: false // We'll never persist this method of logging in.
                    }
                }
            }
        })
    }

    return (
        <PageWrapper>
            <PageTitle>Recover Password</PageTitle>
            <p>Please enter a new password:</p>
            <Form disabled={disabled} onSubmit={handleSubmit} error={error}>
                <LabeledInput
                    label="Password"
                    type="password"
                    required
                    minLength="6"
                    maxLength="32"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                    disabled={disabled}
                />
                <LabeledInput
                    label="Confirm Password"
                    type="password"
                    required
                    minLength="6"
                    maxLength="32"
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value)
                    }}
                    disabled={disabled}
                />
            </Form>
        </PageWrapper>
    )
}

RecoverPage.propTypes = {
    tokenUser: userPropType,
    tokenError: apiErrorPropType
}

export default RecoverPage
