import React from 'react'
import { useDealership } from 'DealershipContext'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import SupportForm from './components/SupportForm'

const SupportPage = () => {
    const [submitted, setSubmitted] = React.useState(false)
    const { dealership } = useDealership()

    return (
        <PageWrapper>
            <PageTitle>Request Support</PageTitle>
            {dealership.email ? (
                !submitted ? (
                    <SupportForm
                        onSuccess={() => {
                            setSubmitted(true)
                        }}
                    />
                ) : (
                    <p>Thank you for your message!</p>
                )
            ) : (
                <p>
                    Your dealership did not supply a contact email. Please reach
                    out to them directly with any questions.
                </p>
            )}
        </PageWrapper>
    )
}

export default SupportPage
