import PropTypes from 'prop-types'
import { formatClassNames } from 'services/fmt'
import styles from './DatePicker.module.css'
import { default as ReactDatePicker} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

/**
 * DatePicker has some common styling applied to it in keeping with the PayMyCar styles,
 * similar to LabeledInput but without the use of a ref which conflicted with the underlying
 * react-datepicker component. For a full list of props that can be passed into the datepicker,
 * see https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
 * @param {*} label - Label for the input.
 * @param {*} className - Optional styling
 */
const DatePicker = ({ label, className, ...rest }) => {
    return (
        <label
            className={formatClassNames([styles.label, className])}
        >
            <span>{label}</span>
            <ReactDatePicker {...rest} />
        </label>
    )
}

DatePicker.displayName = 'DatePicker'

DatePicker.propTypes = {
    label: PropTypes.node.isRequired,
    className: PropTypes.any,
}

export default DatePicker
