import React from 'react'
import { sendRequest, newCancelToken } from 'services/api'

/**
 * Configuration for an API request.
 *
 * @typedef {object} apiRequestCfg
 * @property {string} method - Method used for the request (for example, 'get' or 'post').
 * @property {string} endpoint - API endpoint request will hit (for example, '/users/self' or '/dealerships?subdomain=frazermotors').
 * @property {object} body - Object for use as JSON request body.
 * @property {transformData} transformData - Function used to transform the response body if no error occurred.
 * @property {transformError} transformError - Function used to transform the response error if an error occurred.
 * @property {chainFn} chain - Function used to chain a follow up request off a previous successful request.
 */

/**
 * Function that returns an apiRequestCfg iff a request should be made.
 *
 * @callback apiRequestFunc
 * @returns {apiRequestCfg|null} Configuration for the request, or null if no request should be made.
 */

/**
 * Function used to transform data.
 *
 * @callback transformData
 * @param {*} data - The raw data.
 * @returns {*} The transformed data.
 */

/**
 * Function used to transform an API error.
 *
 * @callback transformError
 * @param {apiError} error - The raw API error.
 * @returns {*} The transformed API error.
 */

/**
 * Function used to interpret a successful API response's data field and return a follow up request configuration,
 * or nothing if no follow up request should be made.
 *
 * @callback chainFn
 * @param {*} data - Data from the previous API request's response.
 * @returns {apiRequestCfg|null} Configuration for a follow up request; or null if no follow up request should be made.
 */

/**
 * Custom hook to use our API.
 * @param {apiRequestCfg|apiRequestFunc} [initialRequest] - Describes the API request. If null (or evaluated to null for apiRequestFunc), then no request is made until the redo function is used.
 * @param {*} [initialData] - Initial data to be used in the data field of the returned state object.
 * @returns {array} Array with the following elements:
 *   - apiResponse
 *   - Redo function that does or redoes the request optionally with a new apiRequestCfg.
 */
const useApi = (initialRequest, initialData) => {
    // Handle initialRequest being an apiRequestFunc.
    if (typeof initialRequest === 'function') {
        initialRequest = initialRequest()
    }

    // Defer the initial request if no request information was provided.
    const defer = !initialRequest

    const initialState = {
        request: initialRequest,
        redos: 0, // Incremented each time the doRequest function is called.
        response: {
            loading: !defer, // If deferring the initial request, loading should be false.
            error: null,
            data: initialData || null
        }
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case 'START':
                return {
                    ...state,
                    response: {
                        ...state.response,
                        loading: true,
                        error: null
                    }
                }
            case 'DONE':
                if (state.request.chain && !action.payload.error) {
                    const newRequestCfg = state.request.chain(
                        action.payload.data
                    )
                    if (newRequestCfg) {
                        return {
                            ...state,
                            request: newRequestCfg,
                            redos: state.redos + 1
                        }
                    }
                }

                return {
                    ...state,
                    response: {
                        loading: false,
                        error: action.payload.error || null,
                        data: action.payload.data || null
                    }
                }
            case 'REDO':
                return {
                    ...state,
                    request: action.payload || state.request,
                    redos: state.redos + 1,
                    response: {
                        ...state.response,
                        loading: true,
                        error: null
                    }
                }
            default:
                throw new Error()
        }
    }

    const [state, dispatch] = React.useReducer(reducer, initialState)

    // Do the API request.
    React.useEffect(() => {
        // Handle deferring the initial request.
        if (defer && state.redos === 0) {
            return
        }

        dispatch({ type: 'START' })

        // Prep an API request.
        let abort = false
        const cancelToken = newCancelToken()

        const doRequest = async () => {
            const req = state.request
            const res = await sendRequest(
                req.endpoint,
                req.method,
                req.body,
                cancelToken
            )
            if (abort) {
                return
            }

            // Conditionally transform response.
            if (req.transformData && res.data) {
                res.data = req.transformData(res.data)
            }
            if (req.transformError && res.error) {
                res.error = req.transformError(res.error)
            }

            dispatch({ type: 'DONE', payload: res })
        }

        doRequest()

        // If unmounted, flip a flag indicating request results should be discarded and cancel the request if in progress.
        return () => {
            abort = true
            cancelToken.cancel()
        }
    }, [defer, state.request, state.redos])

    // Function that may be used to trigger the request any number of times, optionally with a different request config.
    const redo = (newRequestCfg) => {
        dispatch({ type: 'REDO', payload: newRequestCfg })
    }

    return [state.response, redo]
}

export default useApi
