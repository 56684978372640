import PropTypes from 'prop-types'
import { useDealership } from 'DealershipContext'
import { formatClassNames } from 'services/fmt'

/**
 * Simple <p> element with a message to please contact the dealership to make
 * payment or request information.
 */
const ContactDealership = ({ className }) => {
    const { dealership } = useDealership()

    if (!dealership.phone) {
        return (
            <p className={formatClassNames([className])}>
                Please contact the dealership directly to make a payment or to
                request more information about your account.
            </p>
        )
    }

    return (
        <p className={formatClassNames([className])}>
            Please contact the dealership at{' '}
            <a href={`tel:${dealership.phone}`}>{dealership.phone}</a> to make a
            payment or to request more information about your account.
        </p>
    )
}

ContactDealership.propTypes = {
    className: PropTypes.string
}

export default ContactDealership
