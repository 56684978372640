/**
 * Auto-generated, do not edit.
 * 
 * To adjust config, change `build-configs.js` in the project root.
 */

const cfg = {
  iframeSrcSubdomain: 'frazer',
};

export default cfg
