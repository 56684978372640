import { useDealership } from 'DealershipContext'
import { formatClassNames } from 'services/fmt'
import Copyright from './components/Copyright'
import Nav from './components/Nav'
import FrazerLogo from './images/FrazerLogo.png'
import styles from './Footer.module.css'

/**
 * The standard base page footer.
 */
const Footer = () => {
    const { dealership } = useDealership()

    if (!dealership) {
        return (
            <footer
                className={formatClassNames([styles.footer, styles.blacktext])}
            >
                <p
                    className={formatClassNames([
                        styles.poweredBy,
                        styles.large
                    ])}
                >
                    Powered by
                    <a
                        href="https://www.frazer.com"
                        className={styles.frazerLink}
                    >
                        <img
                            src={FrazerLogo}
                            alt="Frazer Computing Logo"
                            width="80px"
                        />
                    </a>
                </p>
                <Nav />
                <Copyright />
            </footer>
        )
    }

    return (
        <footer className={formatClassNames([styles.footer, styles.whitetext])}>
            <p className={styles.large}>{dealership.business_name}</p>
            <div className={styles.contactInfo}>
                {dealership.description && <p>{dealership.description}</p>}
                {dealership.address && <p>{dealership.address}</p>}
                {dealership.phone && (
                    <p>
                        <a href={`tel:${dealership.phone}`}>
                            {dealership.phone}
                        </a>
                    </p>
                )}
                {dealership.email && (
                    <p>
                        <a href={`mailto:${dealership.email}`}>
                            {dealership.email}
                        </a>
                    </p>
                )}
            </div>
            <Nav />
            <Copyright />
        </footer>
    )
}

export default Footer
