import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import { useUser } from 'UserContext'
import { errorKind } from 'services/api'
import { getPersistLogin } from 'services/storage'
import useApi from 'components/UseApi'
import LabeledInput from 'components/LabeledInput'
import Heading from 'components/Heading'
import Form from 'components/Form'
import styles from './ReauthenticateForm.module.css'

/**
 * ReauthenticateForm is a form used to reauthenticate the currently logged-in user.
 * This may be necessary, for example, before a sensitive operation is allowed.
 */
const ReauthenticateForm = ({ onSuccess, onCancel }) => {
    const { user } = useUser()

    const [password, setPassword] = React.useState('')

    const [authentication, doAuthentication] = useApi()

    // Hook to report rendering of this form one time.
    React.useEffect(() => {
        ReactGA.modalview('reauthenticate-form')
    }, [])

    // Hook to handle authentication success.
    React.useEffect(() => {
        if (authentication.data) {
            onSuccess(authentication.data)
        }
    }, [authentication.data, onSuccess])

    return (
        <Form
            disabled={authentication.loading}
            onSubmit={() => {
                doAuthentication({
                    method: 'post',
                    endpoint: '/authenticate/web',
                    body: {
                        email: user.email,
                        password,
                        persist: getPersistLogin()
                    },
                    transformError: (error) => {
                        if (
                            error.kind === errorKind.REAUTHENTICATION_REQUIRED
                        ) {
                            error.message = 'Incorrect password.'
                        }
                        return error
                    }
                })
            }}
            onCancel={onCancel}
            error={authentication.error && authentication.error.message}
            className={styles.form}
        >
            <Heading size="md">Reauthentication Required</Heading>
            <p>As a security precaution, please re-enter your password.</p>
            <LabeledInput
                label="Password"
                type="password"
                required
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
                disabled={authentication.loading}
            />
        </Form>
    )
}

ReauthenticateForm.propTypes = {
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func
}

export default ReauthenticateForm
