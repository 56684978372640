import React from 'react'
import { Redirect } from 'react-router-dom'
import { useUser } from 'UserContext'
import { errorKind } from 'services/api'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import LabeledInput from 'components/LabeledInput'
import ForgotPasswordLink from 'components/ForgotPasswordLink'
import useApi from 'components/UseApi'
import Form from 'components/Form'

/**
 * Page for user to change their password.
 */
const ChangePasswordPage = () => {
    const { user, setUser } = useUser()

    const [password, setPassword] = React.useState('')
    const [newPassword, setNewPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')

    const [errorMessage, setErrorMessage] = React.useState()
    const [disabled, setDisabled] = React.useState(false)
    const [redirect, setRedirect] = React.useState(false)

    const [response, doRequest] = useApi()

    const handleSubmit = () => {
        setErrorMessage(null)
        setDisabled(true)

        // Ensure new passwords match.
        if (newPassword !== confirmPassword) {
            setErrorMessage(`New password confirmation doesn't match.`)
            setDisabled(false)
            return
        }

        // Ensure password is actually changing.
        if (newPassword === password) {
            setErrorMessage(
                'New password must be different from current password.'
            )
            setDisabled(false)
            return
        }

        // Confirm correct current password (on success, chained request will then change the password).
        // Note that we're potentially overwriting a previously persisted log in here.
        // This is purposeful, since we want the user to have to log back in after the password is changed,
        // and handling it this way allows us to bypass the need for an explicit logout request.
        doRequest({
            method: 'post',
            endpoint: '/authenticate/web',
            body: {
                email: user.email,
                password: password,
                persist: false
            },
            transformError: (e) => {
                // Adjust the returned error to include a message specific to our use here.
                e.change_password_message = 'Failed to change password.'
                if (e.kind === errorKind.REAUTHENTICATION_REQUIRED) {
                    // Authentication failed, most likely because the current password is incorrect.
                    e.change_password_message = 'Current password is incorrect.'
                }
                return e
            },
            chain: (data) => {
                // Checking current password succeeded.
                // Return a request config to change the password.
                return {
                    method: 'put',
                    endpoint: `/users/${data.id}`,
                    body: {
                        email: data.email,
                        nickname: data.nickname,
                        password: newPassword
                    },
                    transformError: (e) => {
                        e.change_password_message = 'Failed to change password.'
                        return e
                    }
                }
            }
        })
    }

    // Hook to handle responses.
    React.useEffect(() => {
        // Handle errors.
        if (response.error) {
            // Display the message specific to our use here if available (it should be).
            setErrorMessage(
                response.error.change_password_message || response.error.message
            )
            setDisabled(false)
            return
        }

        // Handle successful password change.
        if (response.data) {
            // Setting user to null should navigate us away from this page and to the login page.
            // Note that we don't bother with an explicit logout request here because we've already overwritten any previously persisted log in.
            setUser(null)
        }
    }, [response.error, response.data, setUser])

    // Handle the user's decision to cancel the password change.
    if (redirect) {
        return <Redirect push to="/" />
    }

    return (
        <PageWrapper>
            <PageTitle>Change Password</PageTitle>
            <Form
                disabled={disabled}
                onSubmit={handleSubmit}
                onCancel={() => setRedirect(true)}
                submitButtonText="Update"
                error={errorMessage}
            >
                <LabeledInput
                    label="Current Password"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                    disabled={disabled}
                />
                <LabeledInput
                    label="New Password"
                    type="password"
                    required
                    minLength="6"
                    maxLength="32"
                    value={newPassword}
                    onChange={(e) => {
                        setNewPassword(e.target.value)
                    }}
                    disabled={disabled}
                />
                <LabeledInput
                    label="Confirm New Password"
                    type="password"
                    required
                    minLength="6"
                    maxLength="32"
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value)
                    }}
                    disabled={disabled}
                />
            </Form>
            <ForgotPasswordLink />
        </PageWrapper>
    )
}

export default ChangePasswordPage
