import PropTypes from 'prop-types'
import { Route as ReactRouterRoute, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useUser } from 'UserContext'
import { useDealership } from 'DealershipContext'

/**
 * Route to a page.
 */
const Route = ({
    path,
    customerOnly,
    guestOnly,
    dealershipRequired,
    ...rest
}) => {
    const { user } = useUser()
    const { dealership } = useDealership()

    if (customerOnly && !user) {
        return <Redirect to="/login" />
    }
    if (guestOnly && user) {
        return <Redirect to="/" />
    }
    if (dealershipRequired && !dealership) {
        return <Redirect to="/" />
    }

    // Report the page view to analytics.
    // Note that we don't bother with this in the above redirects, because those
    // should end up hitting a non-redirected route and be reported at that
    // time.
    ReactGA.pageview(path)

    return <ReactRouterRoute path={path} {...rest} />
}

Route.propTypes = {
    path: PropTypes.string.isRequired,
    customerOnly: PropTypes.bool,
    guestOnly: PropTypes.bool,
    dealershipRequired: PropTypes.bool
}

export default Route
