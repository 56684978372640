import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import Vehicles from 'components/Vehicles'

/**
 * The home page, which is the primary page for the application.
 */
const HomePage = () => {
    return (
        <PageWrapper>
            <PageTitle>My Vehicles</PageTitle>
            <Vehicles />
        </PageWrapper>
    )
}

export default HomePage
