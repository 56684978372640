import PropTypes from 'prop-types'
import { contractPropType } from 'proptypes'
import { formatDate, formatClassNames } from 'services/fmt'
import { compareDates, parseDateUnix } from 'services/date'
import styles from './VehicleUpdated.module.css'

/**
 * Simple <span> element indicated the date a vehicle (contract) was last
 * updated by the dealership, with some default styling applied if the vehicle
 * was updated today.
 */
const VehicleUpdated = ({ className, contract }) => {
    let style
    let text = 'Update Pending'

    if (!contract.awaiting_update) {
        const today = new Date()
        const modifiedDate = parseDateUnix(contract.modified)

        text = `Updated: ${formatDate(modifiedDate)}`

        if (compareDates(today, modifiedDate) === 0) {
            style = styles.updatedToday
            text = `Updated: Today`
        }
    }

    return <span className={formatClassNames([style, className])}>{text}</span>
}

VehicleUpdated.propTypes = {
    className: PropTypes.string,
    contract: contractPropType.isRequired
}

export default VehicleUpdated
