import PropTypes from 'prop-types'
import { businessHoursPropType } from 'proptypes'
import { formatMilitaryTime } from 'services/fmt'
import styles from './BusinessHours.module.css'

/**
 * Component to display business hours.
 */
const BusinessHours = ({ hours, className, ...rest }) => {
    if (!hours || hours.length !== 7) {
        return null
    }

    // If the hours show closed always, just don't show any hours.
    let noHours = true
    for (let i = 0; i < 7; i++) {
        if (hours[i].open !== 0 || hours[i].close !== 0) {
            noHours = false
            break
        }
    }
    if (noHours) {
        return null
    }

    // It was decided to display hours starting Monday.
    const daysOfWeek = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
    ]

    // Since our API uses weeks starting on Sunday, we need to reorder the array elements to match how we're going to display them.
    // The following just makes a local copy of the array, then shifts the first element off the array and immediately pushes it right back onto the end.
    // (We make a local copy to avoid modifying the passed array, which would be an undesired side-effect for the caller.)
    const hoursCopy = hours.slice()
    hoursCopy.push(hoursCopy.shift())

    const rows = hoursCopy.map((h, index) => {
        let times = (
            <td colSpan="3" className={styles.closed}>
                closed
            </td>
        )
        if (h.open !== 0 || h.close !== 0) {
            const open = formatMilitaryTime(h.open)
            const close = formatMilitaryTime(h.close)
            const openDateTime = formatMilitaryTime(h.open, true)
            const closeDateTime = formatMilitaryTime(h.close, true)
            times = (
                <>
                    <td>
                        <time dateTime={openDateTime}>{open}</time>
                    </td>
                    <td className={styles.separator}>-</td>
                    <td>
                        <time dateTime={closeDateTime}>{close}</time>
                    </td>
                </>
            )
        }

        return (
            <tr key={daysOfWeek[index]}>
                <th scope="row" className={styles.day}>
                    {daysOfWeek[index]}
                </th>
                {times}
            </tr>
        )
    })

    return (
        <table className={styles.hoursTable}>
            <tbody>{rows}</tbody>
        </table>
    )
}

BusinessHours.propTypes = {
    hours: businessHoursPropType,
    className: PropTypes.string
}

export default BusinessHours
