import PropTypes from 'prop-types'
import ButtonGroup from 'components/ButtonGroup'
import Panel from 'components/Panel'
import styles from './Form.module.css'
import { formatClassNames } from 'services/fmt'

/**
 * Form is a <form> with standard buttons and error display.
 *
 * @param {boolean} disabled - True if the form should be disabled. Only affects the submit and cancel buttons, does not affect children.
 * @param {func} onSubmit - Called when the form is submitted, and passed the submit event. The event's `preventDefault` method is already called by default.
 * @param {func} [onCancel] - Called when the form's cancel button is clicked, and passed the click event.
 * @param {string} [submitButtonText=Submit] - Text displayed on the submit button.
 * @param {string} [cancelButtonText=Cancel] - Text displayed on the cancel button.
 * @param {boolean} [coloredButtons] - True if the buttons should be colored (submit green and cancel red).
 * @param {string} [error] - An error message associated with the form.
 */
const Form = ({
    disabled,
    onSubmit,
    onCancel,
    submitButtonText = 'Submit',
    cancelButtonText = 'Cancel',
    error,
    coloredButtons,
    children,
    ...rest
}) => {
    const submitButton = (
        <button
            type="submit"
            disabled={disabled}
            className={formatClassNames([
                coloredButtons && styles.coloredSubmitButton
            ])}
        >
            {submitButtonText}
        </button>
    )

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit(e)
            }}
            {...rest}
        >
            {children}
            {error && (
                <aside>
                    <Panel className={styles.errorPanel}>
                        <p className={styles.errorText}>{error}</p>
                    </Panel>
                </aside>
            )}
            {onCancel ? (
                <ButtonGroup>
                    {submitButton}
                    <button
                        type="button"
                        onClick={onCancel}
                        disabled={disabled}
                        className={formatClassNames([
                            coloredButtons && styles.coloredCancelButton
                        ])}
                    >
                        {cancelButtonText}
                    </button>
                </ButtonGroup>
            ) : (
                submitButton
            )}
        </form>
    )
}

Form.propTypes = {
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    submitButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    coloredButtons: PropTypes.bool,
    error: PropTypes.string,
    children: PropTypes.node
}

export default Form
