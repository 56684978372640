import PropTypes from 'prop-types'
import { formatClassNames } from 'services/fmt'
import styles from './Copyright.module.css'

const Copyright = ({ className, ...rest }) => {
    return (
        <p className={formatClassNames([styles.small, className])} {...rest}>
            <small>
                © 2019-{new Date().getFullYear()} Frazer Computing, LLC. All
                Rights Reserved.
            </small>
        </p>
    )
}

Copyright.propTypes = {
    className: PropTypes.string
}

export default Copyright
