import React from 'react'
import { formatClassNames } from 'services/fmt'
import Link from 'components/Link'
import styles from './HamburgerMenu.module.css'

/**
 * Navigation menu as a collapsible sidebar accessible via a "hamburger" button.
 */
const HamburgerMenu = () => {
    const [collapsed, setCollapsed] = React.useState(true)

    const collapseHandler = (value) => {
        return (e) => {
            e.preventDefault()
            e.stopPropagation()
            setCollapsed(value)
        }
    }

    return (
        <>
            <button
                onClick={collapseHandler(false)}
                className={styles.menuButton}
            >
                ☰
            </button>
            <div
                hidden={collapsed}
                className={styles.overlay}
                onClick={collapseHandler(true)}
            />
            <div
                className={formatClassNames([
                    styles.sidebar,
                    collapsed && styles.collapsed
                ])}
                onClick={collapseHandler(true)}
            >
                <Link to="/profile" className={styles.link}>
                    Profile
                </Link>
                <Link to="/" className={styles.link}>
                    Vehicles
                </Link>
                <Link to="/payment-methods" className={styles.link}>
                    Payment Methods
                </Link>
                <Link to="/past-vehicles" className={styles.link}>
                    Past Vehicles
                </Link>
                <Link to="/contact" className={styles.link}>
                    Contact Us
                </Link>
                <Link to="/logout" className={styles.link}>
                    Log Out
                </Link>
            </div>
        </>
    )
}

export default HamburgerMenu
