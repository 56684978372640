import { useUser } from 'UserContext'
import { errorKind } from 'services/api'
import PageWrapper from 'components/PageWrapper'
import Spinner from 'components/Spinner'
import useApi from 'components/UseApi'
import Link from 'components/Link'
import Heading from 'components/Heading'
import styles from './GenericHome.module.css'

/**
 * The home page when no dealership is specified.
 * If the user is related to multiple, displays a list of dealerships to select from.
 * Otherwise, automatically redirects to the related dealership's subdomain.
 */
const GenericHomePage = () => {
    const { user, setUser } = useUser()

    // Retrieve dealership info for all dealerships that the logged-in user is related to.
    const [dealerships] = useApi({
        method: 'get',
        endpoint: `/dealerships?user_id=${user.id}&order=created_asc`
    })

    // Handle errors.
    if (dealerships.error) {
        if (dealerships.error.kind === errorKind.REAUTHENTICATION_REQUIRED) {
            // User needs to reauthenticate.
            // (This should result in auto navigatation to the login page.)
            setUser(null)
            return (
                <PageWrapper>
                    <Spinner />
                </PageWrapper>
            )
        }
        throw dealerships.error
    }

    // Handle loading.
    if (dealerships.loading) {
        return (
            <PageWrapper>
                <Spinner />
            </PageWrapper>
        )
    }

    // We'll need to know if we're in staging so we can adjust the links / auto-redirect.
    const staging = window.location.hostname.endsWith('staging.paymycar.com')

    switch (dealerships.data.length) {
        case 0:
            // This may happen if a user was a member of a dealership, then their only contract with that dealer was fully deleted.
            return (
                <PageWrapper>
                    <Heading size="sm">
                        No companies are associated with this account.
                    </Heading>
                    <p className={styles.noDealershipText}>
                        A dealership likely created an account for your email,
                        then later deleted it. If you believe this is an error,
                        you may contact your dealership directly, or use our{' '}
                        {<Link to="/support">Support Form</Link>} to request
                        that your account be deleted.
                    </p>
                </PageWrapper>
            )
        case 1:
            // Handle only one dealership.
            window.location.replace(
                `https://${dealerships.data[0].subdomain}${
                    staging ? '.staging' : ''
                }.paymycar.com`
            )
            return (
                <PageWrapper>
                    <Spinner />
                </PageWrapper>
            )
        default:
            // Multiple options.
            return (
                <PageWrapper>
                    <Heading size="sm">
                        Which company are you paying today?
                    </Heading>
                    <ul className={styles.buttonList}>
                        {dealerships.data.map((d) => (
                            <li key={d.id}>
                                <a
                                    href={`https://${d.subdomain}${
                                        staging ? '.staging' : ''
                                    }.paymycar.com`}
                                >
                                    <button type="button">
                                        {d.business_name}
                                    </button>
                                </a>
                            </li>
                        ))}
                    </ul>
                </PageWrapper>
            )
    }
}

export default GenericHomePage
