import Spinner from 'components/Spinner'
import styles from './PaymentFormCallback.module.css'

const PaymentFormCallbackPage = () => {
    // Relevant data should exist in the URL query string.
    // We're going to pass the entire URL as a string and let the receiver parse
    // it.

    // We only want the message to be posted to our site: https://*.paymycar.com.
    // We'll take the full hostname, grab the portion before .paymycar.com (or
    // everything if .paymycar.com isn't present), and then use that plus
    // .paymycar.com as the target. This allows us to be at any subdomain of
    // paymycar.com, but not some non-paymycar.com site.
    //
    // Note 1: The payment form should never be mounted at the root paymycar.com,
    // so we don't need to worry about handling that case.
    //
    // Note 2: We can't access window.parent.location (where the iframe is
    // mounted), so the actual iframe must navigate to a URL with a hostname
    // matching the parent's.
    const subdomains = window.location.hostname
        .split('.paymycar.com', 1)
        .join('')
    let targetOrigin = `https://${subdomains}.paymycar.com`
    if (window.location.hostname === 'localhost') {
        targetOrigin = `http://${window.location.host}`
    }

    window.parent.postMessage(window.location.href, targetOrigin)

    return <Spinner className={styles.spinner} />
}

export default PaymentFormCallbackPage
