import PropTypes from 'prop-types'
import { formatClassNames } from 'services/fmt'
import styles from './ButtonGroup.module.css'

/**
 * Container for a group of buttons that should be spaced in rows of 2 if there is room.
 */
const ButtonGroup = ({ className, children }) => (
    <div className={formatClassNames([styles.buttonGroup, className])}>
        {children}
    </div>
)

ButtonGroup.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}

export default ButtonGroup
