// Functions related to dates (see fmt.js for formatting dates as strings).

import { formatDateISO8601 } from './fmt'

/**
 * Parses a timestamp in unix time format and returns a Date.
 * @param {integer} i - A timestamp in unix time format.
 * @returns {Date} The corresponding JavaScript date.
 */
export const parseDateUnix = (i) => new Date(i * 1000)

/**
 * Parses a date in format "YYYY-MM-DD" and returns a Date with only year, month, and day set.
 * @param {string} d - A date in the format "YYYY-MM-DD".
 * @returns {Date} The date with with zeroed time information.
 */
export const parseDateISO8601 = (d) => {
    const parts = d.split('-')

    if (parts.length !== 3) {
        return new Date(0, 0)
    }

    return new Date(parts[0], parts[1] - 1, parts[2])
}

/**
 * Compare function for two dates, disregarding time information.
 * @param {Date} d1 - First date to compare.
 * @param {Date} d2 - Second date to compare.
 * @returns {int} Zero if the two are the same date, -1 if d1 is the earlier date, and 1 if d2 is the earlier date.
 */
export const compareDates = (d1, d2) => {
    // For convenience we'll just convert to YYYY-MM-DD format and use string comparison.
    const a = formatDateISO8601(d1)
    const b = formatDateISO8601(d2)

    if (a === b) {
        return 0
    }

    return a < b ? -1 : 1
}
