import axios from 'axios'
import config from 'configs/api'

const server = axios.create(config)

/**
 * An API response.
 * @typedef {object} apiResponse
 * @property {*} [data] - Response body. May be null if there was an error, or if loading.
 * @property {boolean} loading - True if the response is loading.
 * @property {apiError} [error] - An error. May be null if no error occurred.
 */

/**
 * An API error.
 * @typedef {object} apiError
 * @property {string} kind - API error kind.
 * @property {string} message - API error message.
 */

/**
 * Parses an error thrown by axios into a standard format API error response.
 * If the error isn't direct from our API, the error response kind will be the
 * empty string.
 * @param {error} err
 * @returns {apiError}
 */
const parseError = (err) => {
    if (err.response) {
        // Server responded with non 2xx code.
        if (err.response.data && err.response.data.error) {
            // Standard format error response.
            return err.response.data.error
        } else {
            // Unexpected format error response.
            return { kind: '', message: 'Server error, please try again.' }
        }
    } else if (err.request) {
        // No response from server.
        return {
            kind: '',
            message: 'No response from server, please try again.'
        }
    } else {
        // Error setting up the request.
        return { kind: '', message: 'Error sending request, please try again.' }
    }
}

/**
 * Sends a request to our API.
 * @param {string} endpoint - API endpoint to send the request to.
 * @param {string} method - HTTP method to be used with for the request.
 * @param {object} body - Body to be sent with the request.
 * @param {object} cancelToken - Axios cancel token source. Generated with axios.CancelToken.source().
 * @returns {apiResponse}
 */
export const sendRequest = async (endpoint, method, body, cancelToken) => {
    // If the API server is running locally (which will only be the case if we're testing),
    // add an arbitrary 1-3 second delay to simulate slow connections.
    if (config.baseURL.startsWith('http://localhost')) {
        const delay = (Math.floor(Math.random() * 3) + 1) * 1000
        console.log(
            `${method} ${endpoint}: simulating slow connection (${Math.round(
                delay / 1000
            )} second delay)...`
        )
        await new Promise((r) => setTimeout(r, delay))
    }

    try {
        const config = {
            method,
            url: endpoint
        }
        if (body) {
            config.data = body
        }
        if (cancelToken) {
            config.cancelToken = cancelToken.token
        }

        const res = await server.request(config)

        return { data: res.data.data }
    } catch (err) {
        return { error: parseError(err) }
    }
}

/**
 * Returns a new cancel token with a 'cancel' method that can be used to cancel a request.
 * @returns {object} An axios cancel token. Has a cancel method that may be used to cancel the related request.
 */
export const newCancelToken = () => {
    return axios.CancelToken.source()
}

/**
 * Enumerated error kinds.
 */
export const errorKind = {
    BAD_REQUEST: 'bad_request',
    NOT_FOUND: 'not_found',
    PERMISSION_DENIED: 'permission_denied',
    REAUTHENTICATION_REQUIRED: 'reauthentication_required'
}
