import PropTypes from 'prop-types'
import Heading from 'components/Heading'
import styles from './PageTitle.module.css'

/**
 * Component that should be used for a page title.
 */
const PageTitle = ({ children }) => (
    <Heading size="xl" className={styles.title}>
        {children}
    </Heading>
)

PageTitle.propTypes = {
    children: PropTypes.node
}

export default PageTitle
