import { Switch, Redirect } from 'react-router-dom'
import { useDealership } from 'DealershipContext'
import { formatClassNames } from 'services/fmt'
import Route from 'components/Route'
import HomePage from './pages/Home'
import ProfilePage from './pages/Profile'
import LoginPage from './pages/Login'
import LogoutPage from './pages/Logout'
import TokenPage from './pages/Token'
import RegisterPage from './pages/Register'
import RecoverPasswordPage from './pages/RecoverPassword'
import RecoverPage from './pages/Recover'
import ChangePasswordPage from './pages/ChangePassword'
import ContactPage from './pages/Contact'
import GenericLoginPage from './pages/GenericLogin'
import GenericHomePage from './pages/GenericHome'
import Header from './components/Header'
import Footer from './components/Footer'
import DisabledPage from './pages/Disabled'
import ConfirmEmailChangePage from './pages/ConfirmEmailChange'
import PaymentMethodsPage from './pages/PaymentMethods'
import AddPaymentMethodPage from './pages/AddPaymentMethod'
import LegalPage from './pages/Legal'
import PrivacyPage from './pages/Privacy'
import VehiclePage from './pages/Vehicle'
import SupportPage from './pages/Support'
import PastVehiclesPage from './pages/PastVehicles'
import styles from './Base.module.css'

/**
 * The base page in which most other pages should live.
 */
const BasePage = () => {
    const { dealership } = useDealership()

    return (
        <div
            className={formatClassNames([
                styles.wrapper,
                dealership && dealership.style_template.wrapper
            ])}
        >
            <Header />
            <main
                className={formatClassNames([
                    styles.main,
                    dealership && dealership.style_template.main
                ])}
            >
                {dealership && dealership.is_disabled ? (
                    <DisabledPage />
                ) : (
                    <Switch>
                        {/* Anyone not logged in. */}
                        <Route
                            path="/login"
                            guestOnly
                            component={
                                dealership ? LoginPage : GenericLoginPage
                            }
                        />
                        <Route
                            path="/register"
                            guestOnly
                            render={() => <TokenPage nextPage={RegisterPage} />}
                        />
                        {/* Logged in users only. */}
                        <Route
                            exact
                            path="/"
                            customerOnly
                            component={dealership ? HomePage : GenericHomePage}
                        />
                        <Route
                            path="/profile"
                            customerOnly
                            component={ProfilePage}
                        />
                        <Route
                            path="/change-password"
                            customerOnly
                            component={ChangePasswordPage}
                        />
                        {/* Logged in users at a specific dealership's site only. */}
                        <Route
                            path="/support"
                            customerOnly
                            dealershipRequired
                            component={SupportPage}
                        />
                        <Route
                            path="/vehicle/:contractid"
                            customerOnly
                            dealershipRequired
                            component={VehiclePage}
                        />
                        <Route
                            path="/past-vehicles"
                            customerOnly
                            dealershipRequired
                            component={PastVehiclesPage}
                        />
                        <Route
                            path="/payment-methods"
                            customerOnly
                            dealershipRequired
                            component={PaymentMethodsPage}
                        />
                        <Route
                            path="/new-payment-method"
                            customerOnly
                            dealershipRequired
                            component={AddPaymentMethodPage}
                        />
                        {/* Anyone at a specific dealership's site. */}
                        <Route
                            dealershipRequired
                            path="/contact"
                            component={ContactPage}
                        />
                        {/* Anyone. */}
                        <Route
                            path="/recover"
                            render={() => <TokenPage nextPage={RecoverPage} />}
                        />
                        <Route path="/logout" component={LogoutPage} />
                        <Route
                            path="/recover-password"
                            component={RecoverPasswordPage}
                        />
                        <Route
                            path="/confirm-email"
                            component={ConfirmEmailChangePage}
                        />
                        <Route path="/legal" component={LegalPage} />
                        <Route path="/privacy" component={PrivacyPage} />
                        {/* Redirect for unrecognized routes. */}
                        <Redirect from="/" to="/" />
                    </Switch>
                )}
            </main>
            <Footer />
        </div>
    )
}

export default BasePage
