import React from 'react'
import { userPropType } from 'proptypes'

export const UserContext = React.createContext()

export const UserProvider = ({ initialUser, ...rest }) => {
    const [user, setUser] = React.useState(initialUser)

    return <UserContext.Provider value={{ user, setUser }} {...rest} />
}

UserProvider.propTypes = {
    initialUser: userPropType
}

export const useUser = () => {
    return React.useContext(UserContext)
}
