import PropTypes from 'prop-types'
import { Link as ReactRouterLink } from 'react-router-dom'
import { formatClassNames } from 'services/fmt'
import styles from './Link.module.css'

/**
 * Link is like <a>, but for linking from page-to-page within the application,
 * as opposed to navigating to an external location.
 *
 * @param {boolean} button - True if the component should be styled like a
 * button. Normally this is achieved by nesting the button, like
 * `<a><button /></a>`, but this component needs to know if the child is a
 * button so that styling can be adjusted.
 * @param {boolean} darkBg - Should be true if the background the link is
 * displayed against is dark, and will adjust styling to be more visible. Has no
 * affect if button is true.
 */
const Link = ({ button, darkBg, className, children, ...rest }) => {
    const classNames = formatClassNames([
        !button && styles.link,
        !button && darkBg && styles.lightBorder,
        className
    ])

    return (
        <ReactRouterLink className={classNames} {...rest}>
            {button ? <button type="button">{children}</button> : children}
        </ReactRouterLink>
    )
}

Link.propTypes = {
    button: PropTypes.bool,
    darkBg: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node
}

export default Link
