import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner'
import useApi from 'components/UseApi'

/**
 * This page expects to find an opaque access token in the query string parameter "t".
 * The token is used to authenticate with our API, and the UserContext is updated with the new user.
 * Once this has been done, nextPage is rendered. If there was an error, the UserContext will have a null user.
 * @param {*} nextPage - The next page to be rendered.
 */
const TokenPage = ({ nextPage }) => {
    // Extract temporary access token from URL query string.
    const parsedURL = new URL(window.location.href)
    const token = parsedURL.searchParams.get('t')

    const [authenticate] = useApi({
        method: 'post',
        endpoint: '/authenticate/web',
        body: { token }
    })

    if (authenticate.loading) {
        return <Spinner />
    }

    return React.createElement(nextPage, {
        tokenUser: authenticate.data,
        tokenError: authenticate.error
    })
}

TokenPage.propTypes = {
    nextPage: PropTypes.node.isRequired
}

export default TokenPage
