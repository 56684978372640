/**
 * Auto-generated, do not edit.
 * 
 * To adjust config, change `build-configs.js` in the project root.
 */

const cfg = {
  gaTrackingID: 'UA-185193513-2',
  options: {
    debug: false,
    gaOptions: {
      cookieDomain: 'paymycar.com'
    },
    testMode: false
  }
};

export default cfg
