import PageWrapper from 'components/PageWrapper'
import Link from 'components/Link'
import LoginForm from 'components/LoginForm'

/**
 * Page for user to log in.
 */
const LoginPage = () => {
    return (
        <PageWrapper>
            <p>
                Sign in to view and manage your vehicle payments, or{' '}
                <Link to="/contact">contact us</Link> to activate your online
                account!
            </p>
            <LoginForm />
        </PageWrapper>
    )
}

export default LoginPage
