import PropTypes from 'prop-types'
import { formatClassNames } from 'services/fmt'
import styles from './Card.module.css'

const Card = ({ topDetail, className, children, ...rest }) => {
    return (
        <section
            className={formatClassNames([styles.card, className])}
            {...rest}
        >
            {topDetail && <p className={styles.topDetail}>{topDetail}</p>}
            {children}
        </section>
    )
}

Card.propTypes = {
    topDetail: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.node
}

export default Card
