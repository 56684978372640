import PropTypes from 'prop-types'
import { paymentMethodPropType } from 'proptypes'
import Card from 'components/Card'
import { parseDateUnix } from 'services/date'
import { formatDate } from 'services/fmt'
import styles from './PaymentMethod.module.css'

const PaymentMethod = ({ pm, onDelete }) => {
    const cardholder = [
        pm.owner_first_name,
        pm.owner_middle_name,
        pm.owner_last_name
    ].reduce((acc, curr) => {
        // Add the current value (a name) to the accumulator (the cardholder value)
        // if the current value isn't the empty string.
        if (curr !== '') {
            // If the accumulator is not empty, add a space before adding the value.
            if (acc !== '') {
                acc += ' '
            }
            acc += curr
        }
        return acc
    }, '')
    const created = formatDate(parseDateUnix(pm.created), true)
    const handleDelete = () => {
        // The onDelete callback function should take the card name as its only argument.
        // This is just used when confirming the deletion, so we'll use nickname if available,
        // and provider+last4 otherwise. This matches how we label payment methods in the payment form.
        const name = pm.nickname || `${pm.provider} ${pm.last_4}`
        onDelete(name)
    }

    return (
        <Card className={styles.card}>
            <table className={styles.table}>
                <caption>
                    {pm.provider} {pm.last_4}
                </caption>
                <tbody>
                    <tr>
                        <th scope="row">Nickname</th>
                        <td>{pm.nickname || '(none)'}</td>
                    </tr>
                    {pm.kind === 'credit' && (
                        <tr>
                            <th scope="row">Expires</th>
                            <td>
                                {pm.expiration_month
                                    .toString()
                                    .padStart(2, '0')}
                                /{pm.expiration_year.toString().substring(2)}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th scope="row">
                            {pm.kind === 'credit'
                                ? 'Cardholder'
                                : 'Account Holder'}
                        </th>
                        <td>{cardholder}</td>
                    </tr>
                    <tr>
                        <th scope="row">Added</th>
                        <td>
                            <time dateTime={created}>{created}</time>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button type="button" onClick={handleDelete}>
                Delete
            </button>
        </Card>
    )
}

PaymentMethod.propTypes = {
    pm: paymentMethodPropType.isRequired,
    onDelete: PropTypes.func.isRequired
}

export default PaymentMethod
