import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useUser } from 'UserContext'
import { useDealership } from 'DealershipContext'
import LabeledInput from 'components/LabeledInput'
import Form from 'components/Form'
import useApi from 'components/UseApi'
import Spinner from 'components/Spinner'
import { errorKind } from 'services/api'

const SupportForm = ({ onSuccess, initialMessage = '' }) => {
    const { user, setUser } = useUser()
    const { dealership } = useDealership()

    const [name, setName] = React.useState(user.nickname)
    const [message, setMessage] = React.useState(initialMessage)
    const [selectedContractId, setSelectedContractId] = React.useState()
    const [cancel, setCancel] = React.useState(false)

    const [supportRequest, doSupportRequest] = useApi(null, 'pre-request') // We initialize data with a sentinel in order to detect success (since on success no data is returned).

    // Fetch contracts.
    const [contracts] = useApi({
        method: 'get',
        endpoint: `/contracts?dealership_id=${dealership.id}&user_id=${user.id}`
    })

    // Hook to handle submit success.
    React.useEffect(() => {
        if (supportRequest.loading || supportRequest.error) {
            return
        }

        if (supportRequest.data === null) {
            onSuccess() // Expected to unmount the form.
        }
    }, [supportRequest, onSuccess])

    // Handle errors fetching contracts.
    if (contracts.error) {
        // If the error is the unauthorized error, change the user to no user.
        // (This should redirect us and cause this component to be unmounted.)
        if (contracts.error.kind === errorKind.REAUTHENTICATION_REQUIRED) {
            setUser(null)
            return <Spinner />
        }

        throw contracts.error
    }

    // Display a spinner until the fetch contracts request completes.
    if (contracts.loading) {
        return <Spinner />
    }

    if (cancel) {
        return <Redirect push to="/" />
    }

    const vehicleOptions = contracts.data
        .sort((a, b) => {
            if (a.payments_disabled === b.payments_disabled) {
                // Disabled flags identical, sort by created time
                if (a.created > b.created) {
                    return -1
                }
                if (a.created < b.created) {
                    return 1
                }
                return 0
            }
            // Disabled flags differ, sort disabled at end.
            return a.payments_disabled ? 1 : -1
        })
        .map((contract) => {
            return (
                <option key={contract.id} value={contract.id}>
                    {contract.model_year} {contract.make} {contract.model}{' '}
                    {contract.payments_disabled ? '(disabled)' : ''}
                </option>
            )
        })

    return (
        <Form
            disabled={supportRequest.loading}
            onSubmit={() => {
                doSupportRequest({
                    method: 'post',
                    endpoint: '/support',
                    body: {
                        dealership_id: dealership.id,
                        contract_id: selectedContractId
                            ? Number(selectedContractId)
                            : 0,
                        name,
                        message
                    }
                })
            }}
            onCancel={() => setCancel(true)}
            error={supportRequest.error && supportRequest.error.message}
        >
            <LabeledInput
                label="Name"
                required
                value={name}
                onChange={(e) => {
                    setName(e.target.value)
                }}
                disabled={supportRequest.loading}
            />
            <LabeledInput
                label="Email"
                type="email"
                required
                value={user.email}
                disabled={true}
            />
            <LabeledInput
                label="Vehicle"
                type="select"
                value={selectedContractId}
                onChange={(e) => {
                    setSelectedContractId(e.target.value)
                }}
                disabled={supportRequest.loading}
            >
                <option key="0" value={0}>
                    No Specific Vehicle
                </option>
                {vehicleOptions}
            </LabeledInput>
            <LabeledInput
                label="Message"
                type="textarea"
                rows="10"
                required
                value={message}
                onChange={(e) => {
                    setMessage(e.target.value)
                }}
                disabled={supportRequest.loading}
            />
        </Form>
    )
}

SupportForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    initialMessage: PropTypes.string
}

export default SupportForm
