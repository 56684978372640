import React from 'react'
import { Redirect } from 'react-router-dom'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import Panel from 'components/Panel'
import NewOpenEdgePaymentMethodForm from './components/NewOpenEdgePaymentMethodForm'
import NewFrazerPayPaymentMethodForm from './components/NewFrazerPayPaymentMethodForm'
import { useDealership } from 'DealershipContext'
import styles from './AddPaymentMethod.module.css'

const AddPaymentMethodPage = () => {
    const [redirect, setRedirect] = React.useState(false)
    const { dealership } = useDealership()

    if (redirect) {
        return <Redirect push to={redirect} />
    }

    return (
        <PageWrapper>
            <PageTitle>Add Payment Method</PageTitle>
            <Panel className={styles.form}>
                {dealership.payment_processor === 'openedge' ? (
                    <NewOpenEdgePaymentMethodForm
                        onSuccess={() => {
                            setRedirect('/payment-methods')
                        }}
                        onCancel={() => {
                            setRedirect('/payment-methods')
                        }}
                    />
                ) : (
                    <NewFrazerPayPaymentMethodForm
                        onSuccess={() => {
                            setRedirect('/payment-methods')
                        }}
                        onCancel={() => {
                            setRedirect('/payment-methods')
                        }}
                    />
                )}
            </Panel>
        </PageWrapper>
    )
}

export default AddPaymentMethodPage
