/**
 * Auto-generated by `build-themes.js`, do not edit.
 * 
 * To adjust themes, change `themes.yaml` in the project root, then run `build-themes.js`.
 */

import styles from './themes.module.css';

const black = {
  wrapper: styles.blackWrapper,
  main: styles.blackMain,
};

const green = {
  wrapper: styles.greenWrapper,
  main: styles.greenMain,
};

const red = {
  wrapper: styles.redWrapper,
  main: styles.redMain,
};

const blue = {
  wrapper: styles.blueWrapper,
  main: styles.blueMain,
};

const fadedblue = {
  wrapper: styles.fadedblueWrapper,
  main: styles.fadedblueMain,
};

const themes = {
  black,
  green,
  red,
  blue,
  fadedblue,
  default: black,
};

export default themes;
