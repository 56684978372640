// Functions related to formatting values as strings.

/**
 * Formats an integer representation of military time to "hh:mm (am|pm)" or "HH:MM".
 * @param {int} mt - Military time.
 * @param {boolean} [keepMT] - If true, formats mt as HH:MM.
 * @returns {string} Formatted time.
 */
export const formatMilitaryTime = (mt, keepMT = false) => {
    let minutes = mt % 100
    minutes = minutes.toString().padStart(2, '0')

    if (keepMT) {
        let hours = Math.floor(mt / 100) % 24
        hours = hours.toString().padStart(2, '0')
        return `${hours}:${minutes}`
    }

    // Not retaining military time format.
    let hours = Math.floor(mt / 100) % 12
    if (hours === 0) {
        hours = 12
    }

    const period = mt % 2400 < 1200 ? 'am' : 'pm'

    return `${hours}:${minutes} ${period}`
}

/**
 * Formats a Date to be in form "YYYY-MM-DD", disregarding timezone adjustments.
 * @param {Date} date - Date to format.
 * @returns {string} Formatted date.
 */
export const formatDateISO8601 = (date) => {
    const y = date.getUTCFullYear().toString()
    const m = (date.getUTCMonth() + 1).toString().padStart(2, '0')
    const d = date
        .getUTCDate()
        .toString()
        .padStart(2, '0')

    return `${y}-${m}-${d}`
}

/**
 * Formats a date in MM/DD/YYYY format.
 * @param {Date} date - Date to format. 
 * @returns {string} Formatted date.
 */
export const formatDateMMDDYYYY = (date) => {
    // Get the pieces of the date we need
    const y = date.getUTCFullYear()
    const m = date.getUTCMonth()
    const d = date.getUTCDate()
    // Return the formatted date
    return `${m + 1}/${d}/${y.toString()}`

}

/**
 * Formats a Date to be in form "January 31st, 2019" or "1/31/19", disregarding timezone adjustments.
 * @param {Date} date - Date to format.
 * @param {boolean} [short] - If true, formats the date as mm/dd/yy.
 * @returns {string} Formatted date.
 */
export const formatDate = (date, short = false) => {
    const y = date.getUTCFullYear()
    const m = date.getUTCMonth()
    const d = date.getUTCDate()

    if (short) {
        return `${m + 1}/${d}/${y.toString().substring(2)}`
    }

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const daySuffixes = [
        'th',
        'st',
        'nd',
        'rd',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th'
    ]

    return `${months[m]} ${d}${
        daySuffixes[d > 10 && d < 14 ? 0 : d % 10]
    }, ${y}`
}

/**
 * Formats an amount representing money (USD) into a string.
 * Amounts that aren't numbers are treated as zero.
 * @param {string|number} amount - Amount of money.
 * @returns {string} Formatted amount of money.
 */
export const formatMoney = (amount) => {
    let n = Number.parseFloat(amount)

    // Treat non-numbers as 0.
    if (Number.isNaN(n)) {
        n = Number.parseFloat('0')
    }

    return n.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

/**
 * Formats an array of class names into a class name to be passed to a component.
 * @param {string[]} names - Class names.
 * @returns {string} A class name to be passed to a component.
 */
export const formatClassNames = (names) => {
    if (!names) {
        return ''
    }

    // Filter out falsey names ('' and undefined usually), then join the remaining space-separated.
    return names.filter((n) => !!n).join(' ')
}
