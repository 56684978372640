import React from 'react'
import PropTypes from 'prop-types'
import { formatClassNames } from 'services/fmt'
import styles from './LabeledInput.module.css'

/**
 * LabeledInput is an <input> or <textarea> nested inside a <label>, with standard styling.
 * Supports obtaining a ref to the nested <input> element if needed.
 * @param {*} label - Label for the input.
 * @param {string} [type=text] - Type of input. All normal <input> types are supported, in addition to "textarea".
 */
const LabeledInput = React.forwardRef(
    ({ label, type, className, ...rest }, ref) => {
        switch (type) {
            case 'checkbox':
            case 'radio':
                return (
                    <label
                        className={formatClassNames([
                            styles.label,
                            styles.inlineLabel,
                            className
                        ])}
                    >
                        <input ref={ref} type={type} {...rest} />
                        <span>{label}</span>
                    </label>
                )
            case 'textarea':
                return (
                    <label
                        className={formatClassNames([styles.label, className])}
                    >
                        <span>{label}</span>
                        <textarea ref={ref} {...rest} />
                    </label>
                )
            case 'select':
                return (
                    <label
                        className={formatClassNames([styles.label, className])}
                    >
                        <span>{label}</span>
                        <select ref={ref} className={styles.select} {...rest} />
                    </label>
                )
            default:
                return (
                    <label
                        className={formatClassNames([styles.label, className])}
                    >
                        <span>{label}</span>
                        <input ref={ref} type={type || 'text'} {...rest} />
                    </label>
                )
        }
    }
)

LabeledInput.displayName = 'LabeledInput'

LabeledInput.propTypes = {
    label: PropTypes.node.isRequired,
    type: PropTypes.string,
    className: PropTypes.string
}

export default LabeledInput
