import PropTypes from 'prop-types'
import { formatClassNames } from 'services/fmt'
import styles from './Panel.module.css'

const Panel = ({ className, children, ...rest }) => (
    <div className={formatClassNames([styles.panel, className])} {...rest}>
        {children}
    </div>
)

Panel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}

export default Panel
