import React from 'react'
import { useUser } from 'UserContext'
import { setPersistLogin } from 'services/storage'
import LabeledInput from 'components/LabeledInput'
import ForgotPasswordLink from 'components/ForgotPasswordLink'
import useApi from 'components/UseApi'
import Form from 'components/Form'
import styles from './LoginForm.module.css'

/**
 * Login form for use by logged out users.
 */
const LoginForm = ({ ...rest }) => {
    const { setUser } = useUser()

    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [persist, setPersist] = React.useState(false)

    const [authentication, doAuthentication] = useApi()

    const handleSubmit = () => {
        doAuthentication({
            method: 'post',
            endpoint: '/authenticate/web',
            body: { email, password, persist }
        })
    }

    // Hook to handle successful authentication.
    React.useEffect(() => {
        if (authentication.data) {
            // Stash the persist value so we can reuse it if we need to reauthenticate later.
            setPersistLogin(persist)
            // Update the user (which should cause this component to be unmounted).
            setUser(authentication.data)
        }
    }, [authentication.data, persist, setUser])

    return (
        <>
            <Form
                disabled={authentication.loading}
                onSubmit={handleSubmit}
                submitButtonText="Log In"
                error={authentication.error && authentication.error.message}
                {...rest}
            >
                <LabeledInput
                    label="Email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    disabled={authentication.loading}
                />
                <LabeledInput
                    label="Password"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                    disabled={authentication.loading}
                />
                <LabeledInput
                    label="Stay logged in."
                    type="checkbox"
                    value={persist}
                    onChange={(e) => {
                        setPersist(e.target.checked)
                    }}
                    disabled={authentication.loading}
                    className={styles.stayLoggedInInput}
                />
            </Form>
            <ForgotPasswordLink />
        </>
    )
}

export default LoginForm
