/**
 * Auto-generated, do not edit.
 * 
 * To adjust config, change `build-configs.js` in the project root.
 */

const cfg = {
  baseURL: 'https://api.paymycar.com',
  timeout: 30 * 1000, // Thirty seconds in milliseconds.
  withCredentials: true,
};

export default cfg
