import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'
import 'normalize.css'
import './index.css'
import App from './App'
import ErrorBoundary from 'components/ErrorBoundary'
import sentryCfg from 'configs/sentry'
import gaCfg from 'configs/google-analytics'

// Initialize sentry error reporting.
Sentry.init({
    dsn: sentryCfg.dsn,
    environment: sentryCfg.environment,
    release: sentryCfg.release
})

// Initialize google analytics.
ReactGA.initialize(gaCfg.gaTrackingID, gaCfg.options)

ReactDOM.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>,
    document.getElementById('root')
)
