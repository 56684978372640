import {
    BrowserRouter as Router,
    Switch,
    Route as ReactRouterRoute
} from 'react-router-dom'
import Route from 'components/Route'
import BasePage from 'pages/Base'
import PaymentFormCallbackPage from 'pages/PaymentFormCallback'
import LoadingPage from 'pages/Loading'
import ErrorPage from 'pages/Error'
import ErrorBoundary from 'components/ErrorBoundary'
import themes from 'themes'
import useApi from 'components/UseApi'
import { errorKind } from 'services/api'
import { UserProvider } from 'UserContext'
import { DealershipProvider } from 'DealershipContext'
import { setPersistLogin } from 'services/storage'

// Subdomain used when testing locally (since localhost doesn't support subdomains).
const testSubdomain = 'frazermotors'

const App = () => {
    // Extract the dealership subdomain.
    let subdomain
    switch (window.location.hostname) {
        case 'localhost':
            // Can't use subdomains on localhost, so manually set to a test dealership.
            subdomain = testSubdomain
            break
        case 'www.staging.paymycar.com':
        case 'staging.paymycar.com':
        case 'www.paymycar.com':
        case 'paymycar.com':
            // We're not at a dealership's subdomain.
            break
        default:
            // It's still possible we're not being served from paymycar.com, in which case we don't want to check for a dealership subdomain.
            if (window.location.hostname.endsWith('.paymycar.com')) {
                // We should be at a dealership's subdomain.
                subdomain = window.location.hostname.replace(
                    new RegExp(/(\.staging)?\.paymycar\.com/i),
                    ''
                )
            }
    }

    // Retrieve dealership matching subdomain if there is a subdomain.
    const [dealership] = useApi(() => {
        if (!subdomain) {
            // Don't make any request.
            return
        }

        // Make the request.
        return {
            method: 'get',
            endpoint: `/dealerships?subdomain=${subdomain}`,
            transformData: (data) => {
                // The retrieved data is initially an array of dealerships matching the search parameter.
                // Because the search parameter is a subdomain, and subdomains are unique, the array should be zero or one in length.
                // We're going to transform this to either null (for no matching dealership) or a single dealership.
                // Additionally, if transformed to a single dealership, we'll also convert the style template from an ID to an object containing CSS information.

                // Handle no dealership matching subdomain.
                if (data.length === 0) {
                    return null
                }

                let d = data[0]

                // Convert dealership.style_template (which is just a theme ID), to the related theme object containing relevant CSS classes.
                d.style_template = themes[d.style_template] || themes.default // Short-circuit evaluate to assigned theme if exists, otherwise use default.

                // Transform data to just the single modified dealership.
                return d
            }
        }
    })

    // Retrieve logged-in user.
    const [user] = useApi({ method: 'get', endpoint: `/users/self` })

    // Handle errors.
    // (If user-related error is caused by no logged in user, then ignore it and allow user to remain null.)
    if (
        (user.error &&
            user.error.kind !== errorKind.REAUTHENTICATION_REQUIRED) ||
        dealership.error
    ) {
        const error = user.error || dealership.error
        return <ErrorPage err={error} user={user.data} />
    }

    // Handle loading.
    if (user.loading || dealership.loading) {
        return <LoadingPage />
    }

    // Handle the case where we have a subdomain but it didn't match a dealership.
    if (subdomain && !dealership.data) {
        // If on localhost, inform developer that the local database needs to be provisioned with the dealership.
        // Otherwise, redirect to the root URL.
        if (window.location.hostname === 'localhost') {
            alert(
                `Create a dealership in the local database with subdomain: "${subdomain}"`
            )
        } else if (window.location.hostname.includes('staging.paymycar.com')) {
            window.location.replace('https://staging.paymycar.com')
        } else {
            window.location.replace('https://paymycar.com')
        }
        return <LoadingPage />
    }

    // If there's no logged-in user, ensure the persist login setting is false.
    if (!user.data) {
        setPersistLogin(false)
    }

    // The payment form callback page is the only one not contained within the base page because
    // it ends up being rendered within an iframe that is already mounted within the base page.
    // TODO (MBF): Refactor the payment form callback page to be more standalone. It shouldn't trigger <App /> mount API requests.
    return (
        <DealershipProvider initialDealership={dealership.data}>
            <UserProvider initialUser={user.data}>
                <Router>
                    <ErrorBoundary hasUserContext={true}>
                        <Switch>
                            <Route
                                path="/oecb"
                                customerOnly
                                component={PaymentFormCallbackPage}
                            />
                            <ReactRouterRoute path="/" component={BasePage} />
                        </Switch>
                    </ErrorBoundary>
                </Router>
            </UserProvider>
        </DealershipProvider>
    )
}

export default App
