import PropTypes from 'prop-types'
import Form from 'components/Form'
import Heading from 'components/Heading'
import { formatMoney, formatDateMMDDYYYY } from 'services/fmt'
import { contractPropType, paymentMethodPropType } from 'proptypes'
import styles from './ConfirmPayment.module.css'

const ConfirmPayment = ({ contract, amount, method, paymentDate, onConfirm, onCancel }) => {
    return (
        <Form
            onSubmit={onConfirm}
            onCancel={onCancel}
            coloredButtons={true}
            submitButtonText="Confirm"
            className={styles.form}
        >
            <Heading>Confirm Payment</Heading>
            <p>
                Please verify and confirm the following information related to
                your payment.
            </p>
            <label>
                Vehicle:
                <span>
                    {contract.model_year} {contract.make} {contract.model}
                </span>
            </label>
            <label>
                Payment Amount:
                <span>{formatMoney(amount)}</span>
            </label>
            <label>
                Payment Method:
                <span>
                    {method.nickname || `${method.provider} ${method.last_4}`}
                </span>
            </label>
            <label>
                Payment Date:
                <span>
                    {formatDateMMDDYYYY(paymentDate)}
                </span>
            </label>
        </Form>
    )
}

ConfirmPayment.propTypes = {
    contract: contractPropType.isRequired,
    amount: PropTypes.number.isRequired,
    method: paymentMethodPropType.isRequired,
    paymentDate: PropTypes.instanceOf(Date).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default ConfirmPayment
