import React from 'react'
import { dealershipPropType } from 'proptypes'

export const DealershipContext = React.createContext()

export const DealershipProvider = ({ initialDealership, ...rest }) => {
    const [dealership] = React.useState(initialDealership)

    return <DealershipContext.Provider value={{ dealership }} {...rest} />
}

DealershipProvider.propTypes = {
    initialDealership: dealershipPropType
}

export const useDealership = () => {
    return React.useContext(DealershipContext)
}
