import { useDealership } from 'DealershipContext'
import { useUser } from 'UserContext'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import ContactDealership from 'components/ContactDealership'
import useApi from 'components/UseApi'
import Spinner from 'components/Spinner'
import styles from './DisabledPage.module.css'

const DisabledPage = () => {
    const { dealership } = useDealership()
    const { user } = useUser()

    // If the user is associated with any other dealerships, we want to give
    // them a link back to the generic home page.
    const [dealerships] = useApi(() => {
        // Only make a request if there's a logged in user.
        if (!user) {
            return null
        }

        return {
            method: 'get',
            endpoint: `/dealerships?user_id=${user.id}&order=created_asc`
        }
    })

    // Handle loading.
    // (Note that we're ignoring errors because the data isn't essential.)
    if (dealerships.loading) {
        return (
            <PageWrapper>
                <Spinner />
            </PageWrapper>
        )
    }

    // We need to know if we're in staging so we can adjust the Home link.
    const staging = window.location.hostname.endsWith('staging.paymycar.com')

    return (
        <PageWrapper>
            <PageTitle>Unavailable</PageTitle>
            <div className={styles.unavailableText}>
                <p>
                    The PayMyCar portal for {dealership.business_name} is
                    currently unavailable.
                </p>
                <ContactDealership />
                {!dealerships.error &&
                    dealerships.data &&
                    dealerships.data.length > 1 && (
                        <a
                            href={`https://${
                                staging ? 'staging.' : ''
                            }paymycar.com`}
                        >
                            <button type="button">Back</button>
                        </a>
                    )}
            </div>
        </PageWrapper>
    )
}

export default DisabledPage
