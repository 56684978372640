import PropTypes from 'prop-types'
import { formatClassNames } from 'services/fmt'
import styles from './PageWrapper.module.css'

/**
 * Component that should be used to wrap the contents of a page.
 */
const PageWrapper = ({ className, children }) => (
    <div className={formatClassNames([styles.wrapper, className])}>
        {children}
    </div>
)

PageWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}

export default PageWrapper
