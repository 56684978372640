import { Link } from 'react-router-dom'
import { useUser } from 'UserContext'
import { useDealership } from 'DealershipContext'
import { formatClassNames } from 'services/fmt'
import PayMyCarLogo from 'components/PayMyCarLogo'
import HamburgerMenu from './components/HamburgerMenu'
import styles from './Header.module.css'

/**
 * The standard base page header.
 */
const Header = () => {
    const { dealership } = useDealership()
    const { user } = useUser()

    if (!dealership) {
        return (
            <header
                className={formatClassNames([
                    styles.genericHeader,
                    styles.blacktext
                ])}
            >
                <Link to="/">
                    <PayMyCarLogo width="400" className={styles.pmcLogo} />
                </Link>
                {user && (
                    <Link to="/logout" className={styles.genericLogOutLink}>
                        Log Out
                    </Link>
                )}
            </header>
        )
    }

    return (
        <header className={formatClassNames([styles.header, styles.whitetext])}>
            <Link to="/" className={styles.homeLink}>
                {dealership.business_name}
            </Link>
            <div className={styles.paymycarLogoContainer}>
                <Link to="/">
                    <PayMyCarLogo width="140" light />
                </Link>
            </div>
            {user && <HamburgerMenu />}
        </header>
    )
}

export default Header
