import React from 'react'
import { useUser } from 'UserContext'
import { errorKind } from 'services/api'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import Link from 'components/Link'
import LabeledInput from 'components/LabeledInput'
import Overlay from 'components/Overlay'
import ReauthenticateForm from 'components/ReauthenticateForm'
import useApi from 'components/UseApi'
import Form from 'components/Form'

/**
 * Profile page with basic information about the logged in user, and functionality for updating that information.
 */
const ProfilePage = () => {
    const { user, setUser } = useUser()

    const nameInputEl = React.useRef(null)

    const initialState = {
        nickname: user.nickname,
        email: user.email,
        confirmEmail: '',
        edit: false,
        disableInput: false,
        submit: false,
        reauthenticate: false,
        errorMessage: '',
        message: ''
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case 'NICKNAME_CHANGED':
                return {
                    ...state,
                    nickname: action.payload
                }
            case 'EMAIL_CHANGED':
                return {
                    ...state,
                    email: action.payload
                }
            case 'CONFIRM_EMAIL_CHANGED':
                return {
                    ...state,
                    confirmEmail: action.payload
                }
            case 'EDIT':
                nameInputEl.current.focus()
                return {
                    ...state,
                    nickname: '',
                    email: '',
                    confirmEmail: '',
                    edit: true
                }
            case 'SUBMIT':
                return {
                    ...state,
                    disableInput: true,
                    submit: true,
                    errorMessage: ''
                }
            case 'SUBMITTED':
                return {
                    ...state,
                    submit: false
                }
            case 'ERROR':
                return {
                    ...state,
                    disableInput: false,
                    submit: false,
                    errorMessage: action.payload
                }
            case 'REAUTHENTICATE':
                return {
                    ...state,
                    submit: false,
                    reauthenticate: true
                }
            case 'REAUTHENTICATED':
                // Retry the edit operation.
                return {
                    ...state,
                    submit: true,
                    reauthenticate: false
                }
            case 'USER_CHANGED':
                return {
                    ...state,
                    nickname: action.payload.nickname,
                    email: action.payload.email,
                    confirmEmail: '',
                    edit: false,
                    disableInput: false,
                    submit: false,
                    message:
                        state.email && state.email !== action.payload.email
                            ? `Finalize the email address change by using the link sent to "${state.email}".`
                            : ''
                }
            case 'RESET':
                return initialState
            default:
                throw new Error()
        }
    }

    const [state, dispatch] = React.useReducer(reducer, initialState)

    const [updateUser, doUpdateUser] = useApi()

    // Handle form submission.
    React.useEffect(() => {
        if (!state.submit) {
            return
        }

        // Ensure emails match if provided.
        if (state.email !== state.confirmEmail) {
            dispatch({
                type: 'ERROR',
                payload: "New email confirmation doesn't match."
            })
            return
        }

        if (
            (state.nickname === '' || state.nickname === user.nickname) &&
            (state.email === '' || state.email === user.email)
        ) {
            // No change.
            dispatch({ type: 'RESET' })
            return
        }

        dispatch({ type: 'SUBMITTED' })

        doUpdateUser({
            method: 'put',
            endpoint: `/users/${user.id}`,
            body: {
                email: state.email || user.email,
                nickname: state.nickname || user.nickname
            }
        })
    }, [state, user, doUpdateUser])

    // Hook to handle API response.
    React.useEffect(() => {
        if (updateUser.loading) {
            return
        }

        if (updateUser.error) {
            if (updateUser.error.kind === errorKind.REAUTHENTICATION_REQUIRED) {
                // This is the unauthorized error, which means the user needs to reauthenticate.
                dispatch({ type: 'REAUTHENTICATE' })
                return
            }
            dispatch({ type: 'ERROR', payload: updateUser.error.message })
            return
        }

        if (updateUser.data) {
            setUser(updateUser.data)
            dispatch({ type: 'USER_CHANGED', payload: updateUser.data })
        }
    }, [updateUser, setUser])

    return (
        <PageWrapper>
            <PageTitle>Profile</PageTitle>
            {state.message ? (
                <p>{state.message}</p>
            ) : (
                <>
                    <Form
                        disabled={state.disableInput}
                        onSubmit={() => {
                            if (state.edit) {
                                dispatch({ type: 'SUBMIT' })
                            } else {
                                dispatch({ type: 'EDIT' })
                            }
                        }}
                        onCancel={
                            state.edit
                                ? () => dispatch({ type: 'RESET' })
                                : null
                        }
                        submitButtonText={state.edit ? 'Submit' : 'Edit'}
                        error={state.errorMessage}
                    >
                        <LabeledInput
                            id="profile-name-input"
                            label="Name"
                            value={state.nickname}
                            placeholder={user.nickname}
                            onChange={(e) => {
                                dispatch({
                                    type: 'NICKNAME_CHANGED',
                                    payload: e.target.value
                                })
                            }}
                            readOnly={!state.edit}
                            disabled={state.disableInput}
                            ref={nameInputEl}
                        />
                        <LabeledInput
                            label="Email"
                            type="email"
                            value={state.email}
                            placeholder={user.email}
                            onChange={(e) => {
                                dispatch({
                                    type: 'EMAIL_CHANGED',
                                    payload: e.target.value
                                })
                            }}
                            readOnly={!state.edit}
                            disabled={state.disableInput}
                        />
                        {state.edit && (
                            <LabeledInput
                                label="Confirm Email"
                                type="email"
                                value={state.confirmEmail}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'CONFIRM_EMAIL_CHANGED',
                                        payload: e.target.value
                                    })
                                }}
                                readOnly={!state.edit}
                                disabled={state.disableInput}
                                onCopy={(e) => {
                                    // Prevent copy.
                                    e.preventDefault()
                                }}
                                onPaste={(e) => {
                                    // Prevent paste.
                                    e.preventDefault()
                                }}
                            />
                        )}
                    </Form>
                    <Link to="/change-password">Change Password</Link>
                    {state.reauthenticate && (
                        <Overlay>
                            <ReauthenticateForm
                                onSuccess={(user) =>
                                    dispatch({
                                        type: 'REAUTHENTICATED',
                                        payload: user
                                    })
                                }
                                onCancel={() => dispatch({ type: 'RESET' })}
                            />
                        </Overlay>
                    )}
                </>
            )}
        </PageWrapper>
    )
}

export default ProfilePage
