import PropTypes from 'prop-types'
import { useUser } from 'UserContext'
import { useDealership } from 'DealershipContext'
import { formatClassNames } from 'services/fmt'
import Link from 'components/Link'
import styles from './Nav.module.css'

const Nav = ({ className, ...rest }) => {
    const { user } = useUser()
    const { dealership } = useDealership()

    return (
        <nav
            className={formatClassNames([
                styles.nav,
                dealership && styles.dealershipSpecific,
                className
            ])}
            {...rest}
        >
            <ul>
                {user && dealership && (
                    <li>
                        <Link darkBg={!!dealership} to="/support">
                            PayMyCar Support
                        </Link>
                    </li>
                )}
                <li>
                    <Link darkBg={!!dealership} to="/legal">
                        Terms of Service
                    </Link>
                </li>
                <li>
                    <Link darkBg={!!dealership} to="/privacy">
                        Privacy Policy
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

Nav.propTypes = {
    className: PropTypes.string
}

export default Nav
