import React from 'react'
import PropTypes from 'prop-types'
import { useDealership } from 'DealershipContext'
import useApi from 'components/UseApi'
import LabeledInput from 'components/LabeledInput'
import Heading from 'components/Heading'
import Spinner from 'components/Spinner'
import styles from './NewOpenEdgePaymentMethodForm.module.css'

/**
 * Form used to finalize creation of a new payment method using OpenEdge (provider-supplied form is mounted in an iFrame).
 */
const NewOpenEdgePaymentMethodForm = ({ onSuccess, onCancel }) => {
    const { dealership } = useDealership()

    const initialState = {
        nickname: '',
        ownerIsBusiness: false,
        finalizing: 0, // 0 initially, 1 once mounted form is submitted, 2 once api request is sent
        error: null // Like finalize.error, but dismissable.
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_NICKNAME':
                return {
                    ...state,
                    nickname: action.payload
                }
            case 'SET_OWNER_IS_BUSINESS':
                return {
                    ...state,
                    ownerIsBusiness: action.payload
                }
            case 'MOUNTED_FORM_SUBMITTED':
                return {
                    ...state,
                    finalizing: 1
                }
            case 'FINALIZING':
                return {
                    ...state,
                    finalizing: 2
                }
            case 'FINALIZING_ERROR':
                return {
                    ...state,
                    error: action.payload
                }
            case 'RESET':
                return initialState
            default:
                throw new Error()
        }
    }

    const [state, dispatch] = React.useReducer(reducer, initialState)

    // Prepare to create a new payment method by retrieving the payment processor form URL and transaction ID.
    const [processorForm, refetchProcessorForm] = useApi({
        method: 'post',
        endpoint: '/payment-methods/openedge',
        body: { dealership_id: dealership.id }
    })

    // Prepare a request to finalize payment method creation.
    const [finalize, doFinalize] = useApi()

    // Hook to register and unregister the iFrame message listener.
    React.useEffect(() => {
        const receiveIFrameMessage = (e) => {
            // e should contain a data field with a URL and query string.
            let params
            try {
                params = new URL(e.data).searchParams
            } catch (err) {
                // This is some other message.
                return
            }

            // If the query string includes an action=cancel pair, then the user pressed the cancel button on the mounted form.
            if (params.get('action') === 'cancel') {
                // User pressed the cancel button.
                onCancel() // Expected to unmount this component.
                return
            }

            dispatch({ type: 'MOUNTED_FORM_SUBMITTED' })
        }

        window.addEventListener('message', receiveIFrameMessage)

        return () => {
            window.removeEventListener('message', receiveIFrameMessage)
        }
    }, [onCancel])

    // Hook to handle iFrame form submission.
    React.useEffect(() => {
        // We only want this hook to fire in its entirety once when the mounted form is submitted.
        if (state.finalizing !== 1) {
            return
        }

        // Mark finalizing as in progress.
        // This changes the finalizing stage, causing subsequent execution of this hook to return early (like the one triggered by the following doFinalize call).
        dispatch({ type: 'FINALIZING' })

        doFinalize({
            method: 'post',
            endpoint: '/payment-methods/openedge/finalize',
            body: {
                dealership_id: dealership.id,
                processor_order_id: processorForm.data.processor_transaction_id,
                nickname: state.nickname,
                owner_is_business: state.ownerIsBusiness
            }
        })
    }, [
        state.finalizing,
        doFinalize,
        dealership.id,
        processorForm.data,
        state.nickname,
        state.ownerIsBusiness
    ])

    // Hook to handle receiving finalize results.
    React.useEffect(() => {
        if (finalize.error) {
            dispatch({ type: 'FINALIZING_ERROR', payload: finalize.error })
        } else if (finalize.data) {
            onSuccess(finalize.data) // Expected to unmount this component.
        }
    }, [finalize.error, finalize.data, onSuccess])

    let contents
    if (processorForm.loading) {
        // Still retrieving form.
        contents = <Spinner />
    } else if (processorForm.error) {
        // Error retrieving form.
        contents = (
            <>
                <Heading size="md">Error Preparing Form</Heading>
                <p>{processorForm.error.message}</p>
                <button type="button" onClick={onCancel}>
                    OK
                </button>
            </>
        )
    } else if (state.error) {
        // Error finalizing.
        // Note that we don't use finalize.error directly in order to support dismissing the error and retrying.
        contents = (
            <>
                <Heading size="md">Unsuccessful</Heading>
                <p>{state.error.message}</p>
                <button
                    type="button"
                    onClick={() => {
                        refetchProcessorForm()
                        dispatch({ type: 'RESET' })
                    }}
                >
                    OK
                </button>
            </>
        )
    } else {
        // No errors, and done loading.
        contents = (
            <>
                <LabeledInput
                    label="Nickname"
                    type="text"
                    placeholder="My Card"
                    maxLength="30"
                    value={state.nickname}
                    onChange={(e) => {
                        dispatch({
                            type: 'SET_NICKNAME',
                            payload: e.target.value
                        })
                    }}
                    disabled={state.finalizing > 0}
                />
                <LabeledInput
                    label="Corporate card"
                    type="checkbox"
                    checked={state.ownerIsBusiness}
                    onChange={(e) => {
                        dispatch({
                            type: 'SET_OWNER_IS_BUSINESS',
                            payload: e.target.checked
                        })
                    }}
                    disabled={state.finalizing > 0}
                />
                <iframe
                    title="OpenEdgeForm"
                    src={processorForm.data.form_url}
                    className={styles.iframe}
                />
            </>
        )
    }

    return <div className={styles.form}>{contents}</div>
}

NewOpenEdgePaymentMethodForm.propTypes = {
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func
}

export default NewOpenEdgePaymentMethodForm
