import PropTypes from 'prop-types'
import { formatClassNames } from 'services/fmt'
import styles from './Heading.module.css'

/**
 * Heading (<h1>).
 *
 * @param {string} size - Size of the heading. Must be one of xs, sm, md, lg, xl.
 * @param {boolean} [bold] - Whether or not the heading is bold.
 */
const Heading = ({ size, bold, className, children }) => (
    <h1
        className={formatClassNames([
            styles.heading,
            styles[size],
            bold && styles.bold,
            className
        ])}
    >
        {children}
    </h1>
)

Heading.propTypes = {
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
    bold: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node
}

export default Heading
