import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    formatDateMMDDYYYY,
    formatMoney,
    formatClassNames
} from 'services/fmt'
import { parseDateUnix } from 'services/date'
import useApi from 'components/UseApi'
import Panel from 'components/Panel'
import styles from './ScheduledPayments.module.css'
import Overlay from 'components/Overlay'
import Heading from 'components/Heading'
import Spinner from 'components/Spinner'

const ScheduledPayments = ({ contractID, className, ...rest }) => {
    // State to determine whether or not to render confirmation prompt
    const [confirm, setConfirm] = useState(false)
    const [confirmationData, setConfirmationData] = useState({}) // { date, amount, paymentID}
    // If an error occurred while executing the operation
    const [errorMessage, setErrorMessage] = useState("")
    // API Call to get scheduled transactions
    // We ignore the returned refetch function as we don't need it currently
    // (Get requests call on mount)
    const [scheduledPayments, getScheduledPayments] = useApi()
    // API Calls to edit a scheduled transaction
    // We do this by deleting the existing one, and then creating a new one
    // as there is no update endpoint for scheduled transactions.
    // 
    // We'll leave initial config blank, and pass in our desired config
    // into the "do" function
    const [deletePayment, doDeletePayment] = useApi()

    useEffect(() => {
        // Do we actually have a contract ID?
        if (contractID) {
            getScheduledPayments({
                method: 'get',
                endpoint: `/transactions/${contractID}`
            })
        }
        // Adding getScheduledPayments would cause infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractID])

    // Hooks to handle API responses
    useEffect(() => {
        // Reset the error message if it changed
        setErrorMessage("")
        // Ignore if the API call is loading
        if (deletePayment.loading) {
            return
        }
        // Detect error response
        if (deletePayment.error) {
            // If we encountered an error, inform the user (and if we have a message, add it)
            // If no error it will just add a period on the end of the generic message
            let errMsg = "." 
            if (deletePayment.error.message) {
                // If we do have one, add a colon and the message
                errMsg = `: ${deletePayment.error.message}` 
            }
            setErrorMessage(`An error occurred while attempting to delete scheduled payment${errMsg}`)
            return
        } 
        // Successful deletion
        if (!deletePayment.loading && deletePayment.error == null) {
            setConfirm(false)
            // Refetch data
            getScheduledPayments({
                method: 'get',
                endpoint: `/transactions/${contractID}`
            })
        }
        // Adding getScheduledPayments would cause infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deletePayment, contractID])

    return (
        <>
            { scheduledPayments?.data && Array.isArray(scheduledPayments.data) && scheduledPayments.data.map((payment, i) => (
                <Panel className={styles.panel} key={`scheduled-payment-${i}`}>
                    <p className={formatClassNames([className])} style={{ marginBottom: "0px"}} {...rest}>
                        {formatMoney(payment.amount)} payment scheduled for {formatDateMMDDYYYY(parseDateUnix(payment.scheduled_payment_date))}
                    </p>
                    <button type="button" onClick={() => {
                        setConfirmationData({
                            amount: formatMoney(payment.amount),
                            date: formatDateMMDDYYYY(parseDateUnix(payment.scheduled_payment_date)),
                            paymentID: payment.id,
                        })
                        setConfirm(true)
                    }}>
                        Delete
                    </button>
                </Panel>
            ))}
            { confirm && (
                <Overlay>
                    <Heading>Delete Scheduled Payment</Heading>
                    { errorMessage ? (
                        <>
                            <p>{errorMessage}</p>
                            <br />
                            <button 
                                type="button" 
                                className={formatClassNames([
                                    styles.coloredCancelButton
                                ])}
                                onClick={() => {
                                    setConfirm(false)
                                    setErrorMessage("")
                                }}
                            >
                                Back
                            </button>
                        </>
                    ) : (
                        <>
                        { deletePayment.loading ? (
                            <Spinner />
                        ) : (
                            <>
                                <p>
                                Are you sure you wish to delete this scheduled payment?
                                </p>
                                <label className={formatClassNames([
                                    styles.label
                                ])}>
                                    Payment Amount:
                                </label>
                                <br/>
                                {confirmationData.amount}
                                <br/>
                                <label className={formatClassNames([
                                    styles.label
                                ])}>
                                    Payment Date:
                                </label>
                                <br/>
                                {confirmationData.date}
                                <br/>
                            </>
                        )}
                        
                        <br/>
                        <button 
                            type="button" 
                            className={formatClassNames([
                                styles.coloredSubmitButton
                            ])}
                            onClick={() => {
                                // If we're already loading, do nothing
                                if (deletePayment.loading) {
                                    return
                                }
                                // Execute operation
                                doDeletePayment({
                                    method: 'delete',
                                    endpoint: `/transactions/${confirmationData.paymentID}/contract/${contractID}`,
                                })
                            }}
                        >
                            { deletePayment.loading ? "Deleting Payment..." : "Yes, Delete Payment" }
                        </button>
                        &nbsp;&nbsp;
                        <button 
                            type="button" 
                            className={formatClassNames([
                                styles.coloredCancelButton
                            ])}
                            onClick={() => setConfirm(false)}
                        >
                            Cancel
                        </button>
                        </>
                    )}
                    
                </Overlay>
            )}
        </> 
    )
}

ScheduledPayments.propTypes = {
    contractID: PropTypes.string,
    className: PropTypes.array
}

export default ScheduledPayments