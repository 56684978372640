import PropTypes from 'prop-types'
import { contractPropType } from 'proptypes'
import {
    formatDateMMDDYYYY,
    formatMoney,
    formatDateISO8601,
    formatClassNames
} from 'services/fmt'
import { parseDateISO8601, compareDates } from 'services/date'
import styles from './VehicleDue.module.css'

/**
 * Simple <p> element containing the due amount and date for a vehicle
 * (contract), with some default styling applied if the amount is overdue.
 */
const VehicleDue = ({ contract, className, ...rest }) => {
    if (contract.awaiting_update) {
        return (
            <p className={formatClassNames([className])} {...rest}>
                Awaiting Update From Dealership
            </p>
        )
    }

    const today = new Date()
    const dueDate = parseDateISO8601(contract.due_date)

    // Compare the due date to today, disregarding time information.
    // If the due date is after today, the returned value will positive.
    // If the due date is today, the value will be zero.
    const dueDateCompare = compareDates(dueDate, today)

    let style, dueText, time
    switch (dueDateCompare) {
        case 0:
            // Due today.
            style = ''
            dueText = 'due'
            time = 'Today'
            break
        case 1:
            // Not yet due
            style = ''
            dueText = 'due on'
            time = formatDateMMDDYYYY(dueDate)
            break
        default:
            // Overdue.
            style = styles.overDue
            dueText = 'due on'
            time = formatDateMMDDYYYY(dueDate)
    }

    return (
        <p className={formatClassNames([style, className])} {...rest}>
            {formatMoney(contract.due_amount)} {dueText}{' '}
            <time dateTime={formatDateISO8601(dueDate)}>{time}</time>
        </p>
    )
}

VehicleDue.propTypes = {
    contract: contractPropType.isRequired,
    className: PropTypes.string
}

export default VehicleDue
