import PageWrapper from 'components/PageWrapper'
import LoginForm from 'components/LoginForm'
import styles from './GenericLogin.module.css'

/**
 * Page for user to log in when dealership isn't specified.
 */
const GenericLoginPage = () => {
    return (
        <PageWrapper>
            <LoginForm className={styles.form} />
        </PageWrapper>
    )
}

export default GenericLoginPage
