import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'
import styles from './Privacy.module.css'
import Link from 'components/Link'

const LegalPage = () => (
    <PageWrapper>
        <PageTitle>Privacy Policy</PageTitle>
        <section className={styles.privacy}>
            <p>
                We at Frazer Computing LLC (“Frazer,” “we,” “us,” or “our”)
                created this privacy policy (“Privacy Policy”) because
                protecting your private information is our priority and we are
                committed to safeguarding the information you provide to us.
                This Privacy Policy is intended to help you understand the terms
                and conditions surrounding the collection and use of your data,
                and explains what personal information we may collect about you
                through our website, when we collect it, how we may use it, and
                who we may disclose it to.
            </p>
            <p>
                By visiting our website located at{' '}
                <Link to="/">https://www.PayMyCar.com</Link> (the “Site”), you
                are agreeing to the terms of this Privacy Policy.
            </p>
            <h4>Collection of Your Personal Information.</h4>
            <div className={styles.subheading}>
                Personal Information We Collect
            </div>
            <p className={styles.indent}>
                When you become a user of the Site (a “User”), you may be asked
                to provide us with certain Personal Information (as defined
                below).
            </p>
            <p className={styles.indent}>
                We collect personally identifiable information that you provide
                or have provided through some other direct contact from you.
                This information may include your name, address, telephone
                number, e-mail address, IP address, bank account number, credit
                card number, debit card number, or any other financial
                information (“Personal Information”) you voluntarily provide to
                use the service, inquire about products and/or services, or to
                opt-in to receiving future communications from us. This Personal
                Information is required to provide the products and/or services
                to you. If you do not provide Personal Information we ask for,
                it may delay or prevent us from providing the products and/or
                services to you.
            </p>
            <div className={styles.subheading}>Usage Information</div>
            <p className={styles.indent}>
                Most websites collect information about your operating system,
                your IP addresses, browser type and language, referring and exit
                pages and URLs, keywords, date and time, amount of time spent on
                particular pages, what sections of a website you visit, and
                similar information concerning your use of the website and
                services by using cookies and pixel tags (also called web
                beacons or clear gifs).
            </p>
            <p className={styles.indent}>
                Cookies are small packets of data that a website stores on your
                computer’s hard drive so that your computer will “remember”
                information about your visit. Clear gifs are tiny graphics with
                a unique identifier, similar in function to cookies, and are
                used to track the online movements of website users. You can
                reject cookies by following the directions provided in your
                Internet provider’s “help” file. If you reject cookies, you may
                still visit the website, but may not be able to use some areas
                of the website and/or the services.
            </p>
            <p className={styles.indent}>
                We use cookies for User authentication when you sign into the
                Site. We do not use pixel tags on the Site but pixel tags are
                used in emails sent by us to track email delivery and open rate.
                Additional data collection using cookies and/or pixel tags may
                be performed by our service providers as more fully described in
                the Google Analytics Section below.
            </p>
            <div className={styles.subheading}>Google Analytics</div>
            <p className={styles.indent}>
                Our Site uses Google Analytics, a web analytics service provided
                by Google, Inc., and Google Remarketing. Google Analytics uses
                cookies to analyze how Users make use of our Site for the
                purpose of evaluating your use of the Site, to generate reports
                on your activities on our Site, and to provide other services
                relating to internet activity and usage. Google will not
                associate your personal IP address as part of Google Analytics
                with any other data held by Google. Google Remarketing is used
                to post advertisements for Users who have already visited our
                Site in the past. This allows adverts tailored to your interests
                to be displayed on our Site within the Google advertising
                network. This facilitates the display within the Google
                advertising network of advertisements specific to the content
                that you have previously accessed on other Google advertising
                network websites where Google’s Remarketing function is used.
                According to its own information, Google does not gather any
                Personal Information within this process. You can disable the
                Google functions by adjusting the settings accordingly at{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="www.google.com/setting/ads"
                >
                    www.google.com/setting/ads
                </a>
                . For more information, please visit:{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/privacy?hl=en-US"
                >
                    https://policies.google.com/privacy?hl=en-US
                </a>
                .
            </p>
            <h4>Purposes for Which We Use Your Personal Information.</h4>
            <p>
                We may use your Personal Information to operate our Site,
                deliver the requested products and/or services, or for marketing
                purposes in a manner that is consistent with this Privacy Policy
                and the context of our relationship with you. In accordance with
                data protection law, we will only use your Personal Information
                if we have a proper reason for doing so. Proper reasons may
                include (i) compliance with legal or regulatory obligations,
                (ii) to perform under a contract with you or to take action
                requested by you in anticipation of entering into a contract,
                (iii) for our legitimate interests or the legitimate interests
                of a third party, or (iv) where you have given consent. A
                legitimate interest is when we have a commercial or business
                reason to use your Personal Information, to the extent your
                rights or interests do not override our legitimate interest.
            </p>
            <p>
                We will use your Personal Information collected through the Site
                for the following purposes:
                <ul>
                    <li>
                        To respond to your requests and to provide you with
                        information or services;
                    </li>
                    <li>
                        To respond to your inquiries and contact you about
                        changes to the Site and/or the services;
                    </li>
                    <li>
                        To send you notices (for example, in the form of
                        e-mails, mailings, and the like) regarding products or
                        services you are receiving;
                    </li>
                    <li>
                        To send you information we think you may find useful or
                        that you have requested from us;
                    </li>
                    <li>
                        Send you information about products or services about
                        which you’ve requested information;
                    </li>
                    <li>
                        To investigate, prevent, or take action regarding
                        illegal activities, suspected fraud, situations
                        involving potential threats to the physical safety of
                        any person, violations of our policies, or as otherwise
                        required by law;
                    </li>
                    <li>
                        For any other purposes disclosed at the time the
                        information is collected or to which you consent; and
                    </li>
                    <li>
                        As otherwise specifically described in this Privacy
                        Policy.
                    </li>
                </ul>
            </p>
            <h4>Disclosure of Personal Information to Third Parties.</h4>
            <p>
                We do not disclose your Personal Information to third parties
                for their direct marketing purposes.
            </p>
            <p>
                We, like many businesses, sometimes hire other companies to
                perform certain business-related functions. Examples include
                mailing information, maintaining databases, hosting services,
                and processing payments. When we employ another company to
                perform a function of this nature, we provide them with the
                information that they need to perform their specific function,
                which may include Personal Information.
            </p>
            <p>
                In the event of an acquisition, we expect that the information
                that we have collected, including Personal Information, would be
                transferred along with our other business assets.
            </p>
            <p>
                We may disclose your Personal Information to government
                authorities and to other third parties when compelled to do so
                by government authorities, at our discretion, or otherwise as
                required by law, including but not limited to in response to
                court orders and subpoenas. We may also disclose your Personal
                Information when we have reason to believe that someone is or
                may be causing injury to or interference with our rights or
                property, other Users of this Site, or anyone else that could be
                harmed by such activities.
            </p>
            <h4>How We Protect Your Information.</h4>
            <p>
                We take commercially reasonable steps to protect the Personal
                Information from loss, misuse, and unauthorized access,
                disclosure, alteration, or destruction. Please understand,
                however, that no security system is impenetrable. We cannot
                guarantee the security of our databases, nor can we guarantee
                that the information you supply will not be intercepted while
                being transmitted to and from us over the Internet. In
                particular, e-mail sent to or from us may not be secure, and you
                should therefore take special care in deciding what information
                you send to us via e-mail. We take precautions to protect your
                information. When you submit sensitive information via our Site,
                your information is protected both online and offline. Whenever
                we collect sensitive information, that information is encrypted
                and transmitted to us in a secure way. Any bank account number,
                credit card number, debit card number, or any other financial
                information you provide is tokenized via a payment processor.
                While we use encryption to protect sensitive information
                transmitted online, we also protect your information offline.
                Only employees who need the information to perform a specific
                job function are granted access to Personal Information. The
                computers and servers in which we store Personal Information are
                kept in a secure environment.
            </p>
            <h4>Children Under Thirteen.</h4>
            <p>
                We believe that it is especially important to protect children’s
                privacy online. We do not knowingly collect Personal Information
                from children under the age of thirteen, regardless of the
                source. Nor do we share with any third party for any purpose
                whatsoever any Personal Information from website visitors
                younger than thirteen years of age. If you are under the age of
                thirteen, you must ask your parent or guardian for permission to
                use our Site. If you believe that a User under the age of 13 may
                have provided us Personal Information in violation of this
                Privacy Policy, please contact us at{' '}
                <a href="mailto:Privacy@Frazer.com"> Privacy@Frazer.com</a>.
            </p>
            <h4>Opt-Out &amp; Unsubscribe.</h4>
            <p>
                We respect your privacy and give you an opportunity to opt-out
                of receiving communications or having your information shared
                with third-parties you’ve indicated you’d like to receive
                information from. Users may opt-out of receiving any or all
                communications from us by contacting us via telephone at
                1-888-963-5369 or by sending an email to{' '}
                <a href="mailto:Privacy@Frazer.com">Privacy@Frazer.com</a>. This
                includes changing or correcting any of your Personal
                Information, requesting that we delete any or all of your
                Personal Information, or expressing concern regarding how we use
                your data.
            </p>
            <h4>Links.</h4>
            <p>
                The Site may contain content, services, advertising and other
                materials that link to websites operated by third parties. We
                have no control over those other websites, and this Privacy
                Policy does not apply to them. Please refer to the privacy
                policies of those websites for more information on how the
                operators of those sites collect and use your Personal
                Information.
            </p>
            <h4>Do Not Track.</h4>
            <p>
                We do not monitor, recognize, or honor any opt-out or do not
                track mechanisms, including general web browser “Do Not Track”
                settings and/or signals.
            </p>
            <h4>Important Notice to Users Outside the U.S.</h4>
            <p>
                The Site and the services are operated in the United States. If
                you are located outside of the United States, please be aware
                that any information you provide to us will be transferred to
                the United States. By providing us with any information through
                the Site or the services, you consent to this transfer.
            </p>
            <h4>Your Rights.</h4>
            <p>
                You may have certain rights relating to your Personal
                Information, subject to local data protection laws. Depending on
                applicable state or federal laws these rights may include the
                right to:
                <ul>
                    <li>Access your Personal Information held by us;</li>
                    <li>
                        Learn more about how we process your Personal
                        Information;
                    </li>
                    <li>Have us correct inaccurate Personal Information;</li>
                    <li>Erase or delete your Personal Information;</li>
                    <li>
                        Restrict our processing of your Personal Information;
                    </li>
                    <li>
                        Receive the Personal Information you provided to us;
                    </li>
                    <li>
                        Transfer your Personal Information to another
                        controller, to the extent possible;
                    </li>
                    <li>
                        Object to any processing of your Personal Information;
                    </li>
                    <li>
                        Opt out of certain disclosures of your Personal
                        Information to third parties;
                    </li>
                    <li>
                        If you’re under the age of 16, or such other applicable
                        age of consent for privacy purposes in relevant
                        individual jurisdictions, opt in to certain disclosures
                        of your Personal Information to third parties;
                    </li>
                    <li>
                        Not be discriminated against for exercising your rights
                        described in this Section;
                    </li>
                    <li>
                        Not be subject to a decision based solely on automated
                        processing (including profiling) that produces legal
                        effects concerning you or similarly significantly
                        affects you; and
                    </li>
                    <li>
                        Withdraw your consent at any time (to the extent we base
                        processing on consent), without affecting the lawfulness
                        of the processing based on such consent before its
                        withdrawal.
                    </li>
                </ul>
                <p className={styles.indent}>
                    Where we process your Personal Information for direct
                    marketing purposes, you can exercise your right to object at
                    any time to such processing without having to provide any
                    specific reason for such objection.
                </p>
                <p className={styles.indent}>
                    To exercise your rights, please contact us by using the
                    information in the “How to Contact Us” Section below. Your
                    Personal Information may be processed in responding to these
                    rights. We try to respond to all legitimate requests within
                    one month unless otherwise required by law, and will contact
                    you if we need additional information from you in order to
                    honor your request or verify your identity. Occasionally it
                    may take us longer than a month, taking into account the
                    complexity and number of requests we receive.
                </p>
            </p>
            <h4>Changes to This Privacy Policy.</h4>
            <p>
                This Privacy Policy is effective as of the date stated at the
                bottom of this Privacy Policy. We may change this Privacy Policy
                from time to time. These changes will be made when appropriate,
                and in a timely manner. We encourage you to examine our Privacy
                Policy from time to time to ensure you are aware of any changes
                we may have made. Your continued use of our Site following an
                update to the Privacy Policy indicates your agreement to the
                then current Privacy Policy.
            </p>
            <h4>How to Contact Us.</h4>
            <p>
                If you have questions about this Privacy Policy, please e-mail
                us at <a href="mailto:Privacy@Frazer.com">Privacy@Frazer.com</a>
                . Please include “PRIVACY POLICY” in the subject line.
            </p>
            <h4>Additional Disclosures for California Residents.</h4>
            <p>
                Under the California Consumer Privacy Act (CCPA) we are required
                to disclose whether we sell Personal Information. We do not sell
                Personal Information, but we may share Personal Information with
                third parties or allow them to collect Personal Information from
                our Site or services if those third parties are authorized
                service providers or business partners who have agreed to our
                contractual limitations as to their retention, use, and
                disclosure of such Personal Information, or if you direct us to
                disclose your Personal Information to third parties.
            </p>
            <p>
                California law grants you the right to know:
                <ul>
                    <li>
                        The categories of Personal Information we have collected
                        about you;
                    </li>
                    <li>
                        The categories of sources from which the Personal
                        Information is collected;
                    </li>
                    <li>
                        Our business or commercial purpose for collecting
                        Personal Information;
                    </li>
                    <li>
                        The categories of third parties with whom we share
                        Personal Information, if any; and
                    </li>
                    <li>
                        The specific pieces of Personal Information we have
                        collected about you.
                    </li>
                </ul>
                Please note that we are not required to:
                <ul>
                    <li>
                        Retain any Personal Information about you that was
                        collected for a single one-time transaction if, in the
                        ordinary course of business, that information about you
                        is not retained;
                    </li>
                    <li>
                        Reidentify or otherwise link any data that, in the
                        ordinary course of business, is not maintained in a
                        manner that would be considered Personal Information; or
                    </li>
                    <li>
                        Provide the Personal Information to you more than twice
                        in a 12-month period.
                    </li>
                </ul>
            </p>
            <p>
                In connection with any Personal Information, we may disclose to
                a third party for a business purpose, you have the right to know
                the categories of Personal information that we disclosed about
                you for a business purpose.
            </p>
            <p>
                Under CCPA and certain other privacy and data protection laws,
                as applicable, you have the right to opt-out of the disclosure
                of your Personal Information. If you exercise your right to
                opt-out of the disclosure of your Personal Information, we will
                refrain from disclosing your Personal Information, unless you
                subsequently provide express authorization for the disclosure of
                your Personal Information. To opt-out of the disclosure of your
                Personal Information, please send you request to{' '}
                <a href="mailto:Privacy@Frazer.com">Privacy@Frazer.com</a>.
            </p>
            <p>
                Subject to certain exceptions set out below, on receipt of a
                verifiable request from you, we will:
                <ul>
                    <li>
                        Delete your Personal Information from our records; and
                    </li>
                    <li>
                        Direct any service providers to delete your Personal
                        Information from their records.
                        <ul>
                            <li>
                                Please note that we may not delete your Personal
                                Information if it is necessary to:
                                <ul>
                                    <li>
                                        Complete the transaction for which the
                                        Personal Information was collected,
                                        provide a good or service requested by
                                        you, or reasonably anticipated within
                                        the context of our ongoing business
                                        relationship with you, or otherwise
                                        perform a contract between you and us;
                                    </li>
                                    <li>
                                        Detect security incidents, protect
                                        against malicious, deceptive,
                                        fraudulent, or illegal activity; or
                                        prosecute those responsible for that
                                        activity;
                                    </li>
                                    <li>
                                        Debug to identify and repair errors that
                                        impair existing intended functionality;
                                    </li>
                                    <li>
                                        Exercise free speech, ensure the right
                                        of another consumer to exercise his or
                                        her right of free speech, or exercise
                                        another right provided for by law;
                                    </li>
                                    <li>
                                        Comply with the California Electronic
                                        Communications Privacy Act;
                                    </li>
                                    <li>
                                        Enable solely internal uses that are
                                        reasonably aligned with your
                                        expectations based on your relationship
                                        with us;
                                    </li>
                                    <li>
                                        Comply with an existing legal
                                        obligation; or
                                    </li>
                                    <li>
                                        Otherwise use your Personal Information,
                                        internally, in a lawful manner that is
                                        compatible with the context in which you
                                        provided the information.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </p>
            <p>
                You have the right to not be discriminated against by us because
                you exercised any of your rights under the CCPA. This means we
                cannot, among other things deny you goods or services for
                exercising these rights.
            </p>
            <p>
                For more information or to exercise your rights, please contact
                us by using the information in the “How to Contact Us” Section
                above.
            </p>
        </section>
    </PageWrapper>
)

export default LegalPage
