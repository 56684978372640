import PropTypes from 'prop-types'
import { contractPropType, paymentPropType } from 'proptypes'
import { formatMoney, formatDate, formatClassNames } from 'services/fmt'
import { parseDateUnix } from 'services/date'

const AccountDetails = ({ contract, lastPayment, className, ...rest }) => {
    const lastPaymentInfo = lastPayment
        ? `${formatMoney(lastPayment.amount)} on ${formatDate(
              parseDateUnix(lastPayment.processed),
              true
          )}`
        : '(none)'

    return (
        <table className={formatClassNames([className])} {...rest}>
            <caption>Account Details</caption>
            <tbody>
                <tr>
                    <th scope="row">Last Online Payment</th>
                    <td>{lastPaymentInfo}</td>
                </tr>
                <tr>
                    <th scope="row">Remaining Balance</th>
                    <td>
                        {contract.awaiting_update
                            ? 'pending'
                            : formatMoney(contract.balance)}
                    </td>
                </tr>
                <tr>
                    <th scope="row">Payoff Amount</th>
                    <td>
                        {contract.awaiting_update
                            ? 'pending'
                            : formatMoney(contract.payoff)}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

AccountDetails.propTypes = {
    contract: contractPropType.isRequired,
    lastPayment: paymentPropType,
    className: PropTypes.string
}

export default AccountDetails
